import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from '@mui/material/Grid';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import ApiService from '../utils/ApiService';
import log from 'loglevel';
import CenteredLoading from './CenteredLoading';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const fetchVehicles = async (vehiclesIds) => {
    if (vehiclesIds && vehiclesIds.length > 0)
        return ApiService.post('/vehicles', { ids: vehiclesIds });

    return [];
}

export const FieldMultiSelect = ({ index, field, options, selectedFieldOptions }) => {
    // log.debug('FieldMultiSelect field', field, 'options', options);
    const [value, setValue] = useState([]);

    // Effect to reset the input value when options is empty
    useEffect(() => {
        if (options.length === 0) {
            setValue([]);
        }
    }, [options]);

    return (
        <Grid item xs={3} >
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                onChange={(_, value) => {
                    selectedFieldOptions(index, value, field.name);
                    setValue(value);
                }}
                disabled={options.length === 0} // disable the field if options is empty
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={field.label} />
                )}
                value={value}
            />
        </Grid>
    );
}

const isExtraOptionVehicle = (extraOptionSelected) => {
    return extraOptionSelected === 'vehicles';
}

export const VehicleExtraOptions = ({ resetFieldOptions, onSearch, extraOptions, extraOptionSelected, setVehicleExtraOption }) => {
    // log.debug('VehicleExtraOptions extraOptions', extraOptions, 'extraOptionSelected', extraOptionSelected);
    const theme = useTheme();
    const handleExtraOptionChange = (event) => {
        setVehicleExtraOption(event.target.value);
    }

    const vehicleOptionSelected = isExtraOptionVehicle(extraOptionSelected);

    return (
        <Grid container sx={{
            display: 'flex', justifyContent: 'flex-end',
            mb: vehicleOptionSelected ? 1 : 0,
            mt: vehicleOptionSelected ? 1 : 0,
            borderBottom: vehicleOptionSelected? 1 : 0,
            borderColor: theme.palette.primary.light
        }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <FormControl sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}>
                    <RadioGroup
                        row
                        name="vehiclesExtraOptions"
                        value={extraOptionSelected}
                        onChange={handleExtraOptionChange}
                    >
                        {extraOptions.map((option, index) => (
                            <FormControlLabel key={index}
                                value={option.name}
                                control={<Radio />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>

            {vehicleOptionSelected &&
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton type="button" aria-label="clear" onClick={resetFieldOptions}>
                        <ClearIcon color='primary' />
                    </IconButton>
                    <IconButton type="button" aria-label="search" onClick={onSearch}>
                        <SearchIcon color='primary' />
                    </IconButton>
                    {/* disable all fields if checkbox and buttoins check checked  */}
                    {/* need one option to go next */}
                </Grid>
            }
        </Grid>
    );
}

const VehicleCompatibility = ({ vehicleCompatibilityField, setFields }) => {
    const [fieldOptions, setFieldOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [vehicleCompatibility, setVehicleCompatibility] = useState(null);
    const [vehicleDefinitions, setVehicleDefinitions] = useState(null);
    const [vehicleOptions, setVehicleOptions] = useState(null);

    const resetFieldOptions = () => {
        setSelectedOptions([]);
        initialSelectedOptions();
        setFieldOptions([]);
        setVehiclesAndSelected([]);
    }
    const canResetFieldsOptions = (fieldOptionsResponse) => {
        if (Object.keys(fieldOptionsResponse).length === 0) {
            resetFieldOptions();
            return true;
        }
        return false;
    }

    const mapSelectedOptions = (selected) => {
        // add name and id to the selected options by index
        return selected.map((options, index) => {
            return {
                [vehicleDefinitions.vehicle_multiselect[index].name]: options?.map((option) => option.id)
            }
        });
    }

    const SelectedOptionsToObject = (selected) => {
        // remove undefined and empty objects
        return selected.filter((options) => Object.keys(options).length > 0).reduce((acc, options) => {
            return { ...acc, ...options }
        }, {});
    }

    const updateFieldOptions = (fieldOptionsResponse) => {
        const updatedFieldOptions = [...fieldOptions];

        // update the field options
        for (const key in fieldOptionsResponse) {
            const indexField = vehicleDefinitions.vehicle_multiselect.findIndex((field) => field.name === key);
            // log.debug('updateFieldOptions indexField', indexField, 'key', key, 'fieldOptionsResponse[key]', fieldOptionsResponse[key]);
            updatedFieldOptions[indexField] = fieldOptionsResponse[key];
        }

        setFieldOptions(updatedFieldOptions);
        log.debug('updateFieldOptions ', updatedFieldOptions);
    }

    const selectedFieldOptions = (index, options, field) => {
        log.debug('selectedFieldOptions', options, "index", index, 'field', field);

        const selected = [...selectedOptions];
        selected[index] = options;
        setSelectedOptions(selected);
        log.debug('selectedFieldOptions selectedOptions', selectedOptions);

        const selectedMap = mapSelectedOptions(selected);
        // log.debug('selectedFieldOptions selectedMap', selectedMap);

        const optionsObject = SelectedOptionsToObject(selectedMap);
        // log.debug('selectedFieldOptions optionsObject', optionsObject);

        if (field == 'brand') { // add to change all filter values only when brand changes
            ApiService.post('/vehicle_filter', { filters: optionsObject }).then((data) => {
                if (canResetFieldsOptions(data)) return;
                updateFieldOptions(data);
            }).catch((error) => {
                log.error('selectedFieldOptions [error]', error);
            });
        }
    }

    const initialSelectedOptions = () => {
        let initialSelectedOptions = [];
        for (let i = 0; i < vehicleDefinitions.vehicle_multiselect.length; i++) {
            initialSelectedOptions.push([]);
        }
        setSelectedOptions(initialSelectedOptions);
    }

    const fetchVehicleDefinitions = async () => {
        if (vehicleCompatibilityField !== undefined) {
            log.debug('fetchVehicleDefinitions vehicle_compatibility', vehicleCompatibilityField.value, 'vehicle_option', vehicleCompatibilityField.vehicle_option);
            if (vehicleCompatibilityField.value === null) {
                setVehicleCompatibility([]);
            } else {
                setVehicleCompatibility(vehicleCompatibilityField.value);
                selectedVehicles(vehicleCompatibilityField.value);
            }
            setVehicleOptions(vehicleCompatibilityField.vehicle_option);
            ApiService.get('/vehicle_definitions').then((data) => {
                if (data.columns !== undefined &&
                    data.vehicle_options !== undefined &&
                    data.vehicle_multiselect !== undefined) {
                    setVehicleDefinitions(data);
                } else {
                    log.error('fetchVehicleDefinitions [error]', 'Invalid response');
                    // TODO: remove logs and set error messages
                }
            }).catch((error) => {
                log.error('fetchVehicleDefinitions [error]', error);
            });
        }
    }

    useEffect(() => {
        if (fieldOptions.length === 0) {
            ApiService.post('/vehicle_brands').then((data) => {
                if (data && data.length > 0)
                    setFieldOptions([data]);
            }).catch((error) => {
                log.error('fetchVehicleDefinitions [error]', error);
            });
        }
    }, [fieldOptions]);

    useEffect(() => {
        if (vehicleDefinitions?.vehicle_multiselect !== undefined)
            initialSelectedOptions();
    }, [vehicleDefinitions]);

    useEffect(() => {
        fetchVehicleDefinitions();
    }, []);

    const selectedVehicles = (vehiclesIds) => {
        if (vehiclesIds && vehiclesIds.length > 0) {
            fetchVehicles(vehiclesIds).then((data) => {
                if (data && data.length > 0) {
                    setVehicles(data);
                }
            }).catch((error) => {
                log.error('selectedVehicles [error]', error);
            });
        }

    }

    const setVehiclesAndSelected = (vehiclesArray) => {
        if (!vehiclesArray)
            return;
        const vehiclesSelected = vehicles.filter(vehicle => vehicleCompatibility.includes(vehicle.id));
        vehiclesArray = vehiclesArray.filter(vehicle => !vehiclesSelected.some(selectedVehicle => selectedVehicle.id === vehicle.id));
        setVehicles([...vehiclesSelected, ...vehiclesArray]);
    }

    const onSearch = () => {
        // log.debug('onSearch selectedOptions', selectedOptions);
        const optionsObject = SelectedOptionsToObject(mapSelectedOptions(selectedOptions));

        ApiService.post('/vehicle_search', { filters: optionsObject }).then((data) => {
            if (data && data.length > 0)
                setVehiclesAndSelected(data);
        }).catch((error) => {
            log.error('onSearch [error]', error);
        });
    }

    const handleSelectionChange = (selectionModel) => {
        setVehicleCompatibility(selectionModel);
        setFields(prevFields => ({
            ...prevFields,
            vehicle_compatibility: { ...prevFields.vehicle_compatibility, value: selectionModel }
        }))
    }

    useEffect(() => {
        if (vehicleOptions === 'universal') {
            setVehicleCompatibility([0]); // need change?
            setFields(prevFields => ({
                ...prevFields,
                vehicle_compatibility: { ...prevFields.vehicle_compatibility, value: [0], vehicle_option: vehicleOptions }
            }))
        }
        else {
            setFields(prevFields => ({
                ...prevFields,
                vehicle_compatibility: { ...prevFields.vehicle_compatibility, vehicle_option: vehicleOptions }
            }))
        }
    }, [vehicleOptions]);


    if( vehicleDefinitions === null || vehicleCompatibility === null || vehicleOptions === null)
        return  <CenteredLoading />

    return (
        <React.Fragment>
            {isExtraOptionVehicle(vehicleOptions) &&
                <Grid container spacing={2}>
                    {vehicleDefinitions.vehicle_multiselect.map((field, index) => (
                        <FieldMultiSelect key={index} index={index} field={field} options={fieldOptions[index] !== undefined ?
                            fieldOptions[index] : []} selectedFieldOptions={selectedFieldOptions} />
                    ))}
                </Grid>
            }
            {vehicleDefinitions.vehicle_options && vehicleDefinitions.vehicle_multiselect &&
                    <VehicleExtraOptions resetFieldOptions={resetFieldOptions} onSearch={onSearch}
                        extraOptions={vehicleDefinitions.vehicle_options}
                        extraOptionSelected={vehicleOptions} setVehicleExtraOption={setVehicleOptions} />}
            {isExtraOptionVehicle(vehicleOptions) && vehicles && vehicles.length > 0 && vehicleDefinitions.columns &&
                <Grid sx={{ mt: 2 }}>
                    <DataGrid
                        checkboxSelection
                        disableRowSelectionOnClick
                        localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, footerRowSelected: () => '' }}
                        rows={vehicles}
                        columns={vehicleDefinitions.columns}
                        onRowSelectionModelChange={handleSelectionChange}
                        rowSelectionModel={vehicleCompatibility}
                        initialState={{
                            ...vehicles.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                    />
                </Grid>
            }
        </React.Fragment>
    );
};

export default VehicleCompatibility;
