import * as Constants from '../utils/constants';
import ApiService from '../utils/ApiService';
import log from 'loglevel';

export default function Logout() {
    ApiService.post("/logout", {}).then((_) => {
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY);
        window.location.assign('/login');
    }).catch((error) => {
        log.error("Logout failed", error);
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY);
        window.location.assign('/login');
    });
}
