import React, { createContext, useContext, useState, useEffect } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [open, setOpen] = useState(true);
    const [drawerVariant, setDrawerVariant] = useState('permanent');

    const toggleOpen = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        const resetDrawer = () => {
            if (window.innerWidth > 768 && window.innerWidth < 1280) {
                setDrawerVariant('permanent');
                setOpen(false);
            } else if (window.innerWidth <= 768) {
                setDrawerVariant('temporary');
                setOpen(false);
            } else {
                setDrawerVariant('permanent');
                setOpen(true);
            }
        }

        window.addEventListener('resize', () => {
            resetDrawer();
        });

        resetDrawer();

        return () => {
            window.removeEventListener('resize', resetDrawer);
        };
    }, []);

    return (
        <SidebarContext.Provider value={{ open, toggleOpen, drawerVariant }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
