import React from 'react';
import Typography from '@mui/material/Typography';
import * as Utils from '../utils/utils';
// import log from 'loglevel';

export default function ProductSubtitle2({ price, stock, stock_location, cost, isActive = true }) {
    // log.debug('ProductSubtitle2: [price, stock, stock_location cost]', price, stock, stock_location, cost);

    if (!price && !stock && !stock_location && !cost) {
        return null;
    }

    return (
        <Typography component="h4" variant="h6" align="center" sx={{ color: isActive ? 'black' : 'red' }}>
            {Utils.productSubtitle2(price, stock, stock_location, cost)}
        </Typography>
    )
}
