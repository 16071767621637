import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import CurrencyTextField from '@puhl/mui-currency-textfield';
import VehicleCompatibility, { fetchVehicles } from './VehicleCompatibility';
import ApiService from '../utils/ApiService';
import log from 'loglevel';
import { Button, Fab } from '@mui/material';
import TextBorder from './border_style/TextBorder';
import { GridAddIcon } from '@mui/x-data-grid';

const DynamicComponent = ({ fields, setFields, step, disableSummary = true }) => {
    log.trace('DynamicComponent::fields:', fields, "isSummaryDisabled", disableSummary);

    const isSummaryDisabled = () => {
        return step === 'summary' && disableSummary;
    }

    const handleFieldChange = (newValue, columnName, valueType, jsonLabel = null) => {
        log.trace('DynamicComponent::handleFieldChange:', newValue, columnName, valueType);

        if (valueType === "json" && jsonLabel !== null) {
            try {
                const existingJson = JSON.parse(fields[columnName].value || '{}');
                existingJson[jsonLabel] = newValue;

                setFields({
                    ...fields,
                    [columnName]: {
                        ...fields[columnName],
                        value: JSON.stringify(existingJson),
                    },
                },
                );
            } catch (error) {
                console.error('Error updating JSON:', error);
            }
        } else {

            if (valueType === "numeric") {
                newValue = newValue
                    .replace(/\./g, ',')
                    .replace(/[^0-9,]/g, '')
                    .replace(/(,.*?),/g, '$1')
                    .replace(/^,/, '');
            }

            const updatedFieldValues = {
                ...fields[columnName],
                value: newValue,
            };

            if (columnName === 'fk_ncm') {
                setFields({
                    ...fields,
                    [columnName]: updatedFieldValues,
                    ['fk_cest']: {
                        ...fields['fk_cest'],
                        ncm: newValue,
                        value: '',
                        disabled: !newValue,
                        options: [],
                    },
                },);
            } else {
                setFields({
                    ...fields,
                    [columnName]: updatedFieldValues,
                },);
            }

            log.debug(updatedFieldValues.name, ':', updatedFieldValues.value);
        }
    };

    const inputProps = (field) => {
        return field.can_be_edited ? {
            readOnly: false, disabled: false
        } : {
            readOnly: true, disabled: true
        };
    };

    const inputLabelProps = (needs_edit) => {
        return needs_edit ? { style: { color: 'red' }, } : {};
    };

    const propsObj = (field) => {
        let result = {};
        result.variant = isSummaryDisabled() ? "standard" : "outlined";
        result.disabled = isSummaryDisabled();
        if (field.value_type !== 'select') {
            if (field.multiline) {
                result.multiline = isSummaryDisabled() ? true : field.multiline;
                if (!isSummaryDisabled()) {
                    result.rows = field.rows ? field.rows : 1;
                }
            }
            result.InputProps = isSummaryDisabled() ? { readOnly: true, disabled: true } : inputProps(field);
            result.InputLabelProps = isSummaryDisabled() ? { readOnly: true, disabled: true } : inputLabelProps(field.needs_edit);
        }
        result.required = field.is_required;
        result.fullWidth = true;
        return result;
    };

    const textFieldNormal = (field, index) => {
        return (
            <Grid item xs={12} key={index}>
                <TextField
                    label={field.name}
                    value={field.value ? field.value : ''}
                    onChange={(e) => handleFieldChange(e.target.value, field.column_name, field.value_type)}
                    {...propsObj(field)}
                />
            </Grid>
        );
    }

    const textFieldCurrency = (field, index) => {
        return (
            <Grid item xs={12} key={index}>
                <CurrencyTextField
                    label={field.name}
                    value={field.value}
                    currencySymbol="R$"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    outputFormat="number"
                    onChange={(_, value) => handleFieldChange(value, field.column_name, field.value_type)}
                    {...propsObj(field)}
                />
            </Grid >
        );
    }

    const textFieldNcmCest = (fieldNcm, FieldCest, index) => {
        // log.debug('DynamicComponent::textFieldNcmCest:', fieldNcm, FieldCest);
        // TODO clear cest optionValue when ncm is empty
        // TODO: loading when search for cest options
        return (textFieldSelectSearch(FieldCest, index));
    }

    const textFieldNcm = (field, index) => {
        return (textFieldSelectSearch(field, index, true, true));
    }

    const textFieldSelectSearch = (field, index, callApiOnTextChange = false, searchOnlyNumeric = false) => {
        const [open, setOpen] = React.useState(false);
        const [search, setSearch] = React.useState(field.value || '');
        const [optionValue, setOptionValue] = React.useState(field.options && field.value ? field.options.find(option => option.value === field.value) : null);
        const loadingValue = field?.options || !open ? false : true;

        const setOptions = (response) => {
            setFields(prev => {
                return {
                    ...prev,
                    [field.column_name]: {
                        ...prev[field.column_name],
                        options: response,
                    },
                }
            })
        }

        // TODO: move this to textFieldNcmCest
        const validateCest = (searchValue) => {
            if (field.column_name === 'fk_cest') {
                log.debug('validateCest fk_cest ncm: ', field.ncm);
                if (!field.ncm) { // todo validate
                    setOptions([]);
                    setSearch('');
                    return '';
                } else {
                    log.debug('validateCest return:', field.ncm);
                    return field.ncm;
                }
            }
            return searchValue;
        }

        const getSearchOptions = (searchValue) => {
            if (!field) return;
            searchValue = validateCest(searchValue);
            log.debug('getSearchOptions search:', searchValue, 'field:', field.column_name);

            ApiService.post('/product_option_search/', { column_name: field.column_name, search: searchValue }).then((response) => {
                setOptions(response ? response : []);
                log.debug('response:', response);
            }
            ).catch((error) => {
                // setErrorMsg(error); // TODO: handle error
                log.error('error:', error, 'searchValue:', searchValue, 'search:', search, 'field:', field.column_name);
            });
        }

        const handleSearchChanged = (e) => {
            if (callApiOnTextChange) {
                let searchValue = e.target.value.replace(/[^0-9]/g, '').substring(0, 8);
                setSearch(searchValue);
                log.debug('searchValue:', searchValue);
                getSearchOptions(searchValue);
            }
        }

        const filterOptions = createFilterOptions({
            stringify: searchOnlyNumeric ? ({ title }) => `${title.replace(/[^0-9]/g, '')}` : ({ title }) => title,
            limit: 100,
        });

        const clearOptions = (value) => {
            if (!value) {
                setSearch('');
                getSearchOptions('');
                setOptionValue(null);
            }
        }

        return (
            < Grid item xs={12} key={index} >
                <Autocomplete
                    open={open}
                    handleHomeEndKeys
                    noOptionsText={'Sem opções'}
                    onOpen={() => {
                        setOpen(true);
                        if (search === '') {
                            getSearchOptions('');
                        }
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option?.title}
                    filterOptions={filterOptions}
                    getOptionDisabled={(option) => option.disabled}
                    options={field?.options ? field.options : []}
                    value={optionValue}
                    onChange={(e, newValue) => {
                        log.debug('onChange newValue:', newValue);
                        // TODO: clean options and value, when ncm than clean cest
                        setOptionValue(newValue);
                        handleFieldChange(newValue?.value, field.column_name, field.value_type);
                        clearOptions(newValue);
                    }}
                    loading={loadingValue}
                    disabled={field.disabled || isSummaryDisabled()}
                    renderInput={(params) => (
                        <TextField
                            label={field.name}
                            onChange={handleSearchChanged}
                            {...propsObj(field)}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingValue ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid >
        );
    }

    const textFieldDatagrid = (field, index) => {
        // TODO: this should be only textFieldDatagrid
        const [vehiclesString, setVehiclesString] = React.useState('');
        if (field.step === 'vehicles') {
            fetchVehicles(field.value).then((resVehicle) => {
                let vehiclesConcat = '';
                if (resVehicle.length === 0) return;
                for (let vehicle in resVehicle) {
                    let vehicleString = '';
                    for (let key in resVehicle[vehicle]) {
                        vehicleString += resVehicle[vehicle][key] + ' ';
                    }
                    vehiclesConcat += vehicleString + '\n';
                }
                setVehiclesString(vehiclesConcat);
            });
        }

        return (
            <Grid item xs={12} key={index} >
                <TextField
                    label={field.name}
                    value={vehiclesString}
                    {...propsObj(field)}
                    InputProps={{ readOnly: true, disabled: true }}
                    InputLabelProps={{ readOnly: true, disabled: false }}
                    multiline={true}
                    rows={5}
                />
            </Grid>
        );
    }

    const textFieldVehicle = (field, index) => {
        const [vehiclesString, setVehiclesString] = React.useState('');
        const [expanded, setExpanded] = React.useState(false);

        const handleExpanded = () => {
            setExpanded(!expanded);
        }

        if (field.step === 'vehicles') {
            fetchVehicles(field.value).then((resVehicle) => {
                let vehiclesConcat = '';
                if (resVehicle.length === 0) return;
                for (let vehicle in resVehicle) {
                    let vehicleString = '';
                    for (let key in resVehicle[vehicle]) {
                        vehicleString += resVehicle[vehicle][key] + ' ';
                    }
                    vehiclesConcat += vehicleString + '\n';
                }
                setVehiclesString(vehiclesConcat);
            });
        }

        return (
            <React.Fragment >
                {expanded ? (
                    <Grid item xs={12} key={index}>
                        <TextBorder label="Compatibilidade de Veículos">
                            <VehicleCompatibility vehicleCompatibilityField={field} setFields={setFields} />
                            {field.vehicle_option === 'vehicles' && (
                                <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleExpanded}
                                        sx={{ mt: 2 }}
                                    >
                                        {expanded ? 'Pronto' : 'Editar'}
                                    </Button>
                                </Grid>
                            )}
                        </TextBorder>
                    </Grid>
                ) : (
                    <Grid item xs={12} key={index} sx={{ position: 'relative' }} onClick={!isSummaryDisabled() ? handleExpanded : null}>
                        <TextField
                            label={field.name}
                            value={vehiclesString}
                            {...propsObj(field)}
                            InputProps={{ readOnly: true, disabled: isSummaryDisabled() }}
                            InputLabelProps={{ readOnly: true, disabled: false }}
                            multiline={true}
                            rows={5}
                            fullWidth
                        />
                        <Fab
                            color="primary"
                            aria-label="add"
                            sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        >
                            <GridAddIcon />
                        </Fab>
                    </Grid>
                )}
            </React.Fragment>
        );
    }

    const selectFieldFullWidth = (field, index) => {
        return (
            <Grid item xs={12} md={12} lg={12} key={index.toString()}>
                <FormControl sx={{ width: '100%', textAlign: 'left' }} >
                    <InputLabel
                        style={field.needs_edit ? { color: 'red' } : {}}>
                        {field.name}
                    </InputLabel>
                    <Select
                        value={field.value ? field.value : ''}
                        label={field.name}
                        onChange={(e) => handleFieldChange(e.target.value, field.column_name, field.value_type)}
                        {...propsObj(field)}>
                        {Object.entries(field.options).map(([, option], index) => (
                            <MenuItem key={index} value={option.id} >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid >
        );
    }

    const textFieldWithSelect = (field, index) => {
        if (!field.parent)
            return '';

        let child = field; // TODO: fix names
        field = fields[field.parent];

        return (
            <Grid container item key={index.toString() + 'a'} spacing={2}>
                <Grid item xs={6} md={8} lg={9} key={index.toString() + 'value'}>
                    <TextField
                        label={field.name}
                        value={field.value ? field.value : ''}
                        onChange={(e) => handleFieldChange(e.target.value, field.column_name, field.value_type)}
                        {...propsObj(field)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3} key={index.toString() + 'child'}>
                    <FormControl sx={{ width: '100%' }} >
                        <InputLabel >{child.name}</InputLabel>
                        <Select
                            value={child.value ? child.value : ''}
                            label={child.name}
                            onChange={(e) => handleFieldChange(e.target.value, child.column_name, child.value_type)}
                            {...propsObj(child)}
                        >
                            {Object.entries(child.options).map(([, option], index) => (
                                <MenuItem key={index} value={option.id} >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const textFieldFromJson = (field) => {
        try {
            let json = JSON.parse(field.value || '{}');
            return (
                Object.entries(json).map(([jsonLabel, jsonValue], jsonIndex) => {
                    return (
                        <Grid item xs={12} key={jsonIndex}>
                            <TextField
                                label={jsonLabel}
                                value={jsonValue ? jsonValue : ''}
                                onChange={(e) => handleFieldChange(e.target.value, field.column_name, field.value_type, jsonLabel)}
                                {...propsObj(field)}
                            />
                        </Grid>)
                })
            );
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    return (
        <Grid container spacing={2}>
            {Object.entries(fields).map(([, field], index) => {
                if (step === field.step || step === 'summary' && !(!field.can_be_edited && field.value === '') && !field.hidden) {
                    switch (field.value_type) {
                        case 'select': {
                            if (field.parent === null && field.child === null) {
                                return (selectFieldFullWidth(field, index))
                            }
                            else if (field.parent !== null) {
                                return (textFieldWithSelect(field, index))
                            }
                            break;
                        }
                        case 'json': {
                            return (textFieldFromJson(field));
                        }
                        case 'datagrid': {
                            if (step === 'summary'){
                                if( field.column_name == 'vehicle_compatibility'){
                                    return (textFieldVehicle(field, index))
                                }
                                else {
                                    return (textFieldDatagrid(field, index)) // TODO: valida if is another datagrid field
                                }
                            }
                            break;
                        }
                        case 'select_search': {
                            if (field.column_name === 'fk_cest') {
                                return (textFieldNcmCest(fields['fk_ncm'], field, index))
                            }
                            else if (field.column_name === 'fk_ncm') {
                                return (textFieldNcm(field, index))
                            }
                            else {
                                return (textFieldSelectSearch(field, index))
                            }
                        }
                        case 'currency': {
                            return (textFieldCurrency(field, index))
                        }
                        default: {
                            if (field.parent === null && field.child === null || field.parent === undefined && field.child === undefined) {
                                return (textFieldNormal(field, index))
                            }
                        }
                    }
                }
            })}
        </Grid>
    );
};

export default DynamicComponent;
