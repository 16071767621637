import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import CenteredLoading from '../components/CenteredLoading';
import DisplayAll from '../components/DisplayAll'
import ModalAlert from '../components/ModalAlert';
import ApiService from '../utils/ApiService';

function DebugProduct() {
    const [product, setProduct] = useState([]);
    const { id } = useParams()
    const [alertMessage, setAlertMessage] = React.useState('');

    const setErrorMsg = (msg) => {
        setProduct({});
        setAlertMessage(msg);
    }
    React.useEffect(() => {
        ApiService.post("/product_data_fields", { "id": Number(id) }).then((response) => {
            setProduct(response);
            setAlertMessage('');
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }, []);

    return (
        <Sidebar sx={{ mt: 2, mb: 2, }}>
            {Object.keys(product).length !== 0 ? <DisplayAll product={product} /> : <CenteredLoading />}
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar>
    );
}

export default DebugProduct;
