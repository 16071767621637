import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import log from 'loglevel';
import ModelAlert from './ModalAlert';
import ProductStepper from './ProductStepper';
import CenteredLoading from './CenteredLoading';
import ProductSubtitle from './ProductSubtitle';
import ProductSubtitle2 from './ProductSubtitle2';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function ProductFields({ productId, returnFunction }) {
    const [product, setProduct] = useState({});
    const [alertMessage, setAlertMessage] = React.useState('');
    const [steps, setSteps] = useState(['title', 'summary']);
    const [stepTitles, setStepTitles] = useState(['Descrição', 'Confirmação']);
    const { displaySetting } = useAuth();

    const setErrorMsg = (msg) => {
        setProduct({});
        setAlertMessage(msg);
    }

    const setErrorMsgSave = (msg) => {
        if (msg.length > 0) {
            setAlertMessage(msg);
        } else {
            setAlertMessage('Erro ao salvar o produto');
        }
    }

    const saveChanges = async (newProduct) => {
        log.debug('ProductFields::saveProducts: [product]', newProduct);

        if (newProduct.fields.vehicle_compatibility?.vehicle_option === 'not_applicable') {
            try {
                await ApiService.post('/update_vehicle_compatibility_status', { product_id: newProduct.id, status: false })
            } catch (error) {
                setErrorMsgSave(error.message);
            }
        }

        // TODO: Verify if this is correct, when update_vehicle_compatibility_status fails, saveChanges still continues

        if (newProduct.fields !== null && Object.keys(newProduct.fields).length > 0)
            ApiService.post('/save_product_data', newProduct).then((_) => {
                returnFunction(true);
            }).catch((error) => {
                setErrorMsgSave(error.message);
            });
        else
            returnFunction(false);
    };

    useEffect(() => {
        window.document.title = 'Dados do Produto | ' + displaySetting('site_name');
        ApiService.post('/product_data_fields', { "id": productId }).then((response) => {
            if (response) {
                window.document.title = `${response.title} | ${displaySetting('site_name')}`;
                setSteps(Object.keys(response.step_titles));
                setStepTitles(Object.values(response.step_titles));
                setProduct(response);
            } else {
                setErrorMsg('Produto não encontrado');
            }
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }, [productId]);

    const handleCloseAlert = () => {
        setAlertMessage('');
        returnFunction(false);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} />}
            {product !== null && <ProductSubtitle2 price={product.price} stock={product.stock}
                stock_location={product.stock_location} cost={product.cost} />}

            {
                Object.keys(product).length !== 0 ?
                    <ProductStepper product={product} setProduct={setProduct} steps={steps} stepTitles={stepTitles} saveChanges={saveChanges} returnFunction={returnFunction} /> :
                    <CenteredLoading />
            }
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} closeButton={handleCloseAlert} />
        </React.Fragment>
    );
}
