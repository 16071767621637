import React, { useEffect, useState, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase } from '@mui/material';

export default function SearchBar({ inputValue, setInputValue, expandable = true, showMobile = false }) {
    const searchBoxRef = useRef(null);
    const [expanded, setExpanded] = useState(!expandable);
    const inputRef = useRef(null);

    const handleClickOutside = (event) => {
        if (searchBoxRef.current && !searchBoxRef.current.contains(event.target) && expandable) {
            if (!inputValue) {
                setExpanded(false);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (inputRef !== null && event.key === 'Escape') {
            handleClearClick();
        }
    };

    const handleSearchChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClearClick = () => {
        setInputValue('');
        if (expandable)
            setExpanded(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputValue]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleExpandClick = () => {
        if (expandable) {
            setExpanded(!expanded);
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 200);
        }
    };

    return (
        <Box
            ref={searchBoxRef}
            sx={{
                display: {
                    xs: showMobile ? 'flex' : 'none',
                    sm: 'flex',
                    md: 'flex',
                    lg: 'flex',
                },
                alignItems: 'center',
                borderBottom: expanded && !expandable ? '1px solid gray' : 'none',
                borderRadius: '0px',
            }}>
            <IconButton onClick={handleExpandClick} sx={{ p: 1 }}>
                <SearchIcon />
            </IconButton>
            {expanded && (
                <>
                    <InputBase
                        placeholder="Buscar..."
                        inputRef={inputRef}
                        value={inputValue}
                        onChange={handleSearchChange}
                        sx={{ ml: 1, flex: 1 }}
                    />
                    {inputValue && (
                        <IconButton onClick={handleClearClick} sx={{ p: 1 }}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </>
            )}
        </Box>
    );
}