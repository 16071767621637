import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';
import ModelAlert from '../components/ModalAlert';
import ProductEditor from '../components/ProductEditor'
import CenteredLoading from '../components/CenteredLoading';
import { useAuth } from '../contexts/AuthContext';
import usePaginatedData from '../utils/usePaginatedData';

export default function ProductsPage() {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [sidebarTitle, setSidebarTitle] = useState('Produtos para Editar');
    const { displaySetting } = useAuth();
    const { data, paginationModel, updatePaginationModel, rowCount, loading, refreshData } = usePaginatedData("/products", true, setAlertMessage);

    const refreshProducts = () => {
        window.document.title = "Produtos | " + displaySetting('site_name');
        refreshData();
    }

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }}>
                {(data !== null) ?
                    <ProductEditor products={data} refreshProducts={refreshProducts} setSidebarTitle={setSidebarTitle} parentTitle={'Produtos para Editar'}
                        paginationModel={paginationModel} updatePaginationModel={updatePaginationModel} rowCount={rowCount} loading={loading}/>
                    : <CenteredLoading />
                }
            </Paper>
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar >
    );
}
