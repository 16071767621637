import React, { useState, useEffect } from 'react';
import ApiService from '../utils/ApiService';
import CenteredLoading from './CenteredLoading';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';


export default function CompanyInfo() {
    const [info, setInfo] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success');

    const errorNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('error');
    }

    const successNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('success');
    }

    useEffect(() => {
        ApiService.get('/company_info').then((response) => {
            setInfo(response);
        }).catch((error) => {
            errorNotification(error.message);
        });
    }, []);

    const saveInfo = () => {
        ApiService.post('/save_company_info', { "info": info }).then((_) => {
            successNotification('Configurações salvas com sucesso');
        }).catch((error) => {
            errorNotification(error.message);
        });
    }

    const content = () => {
        return (
            <Box>
                {info !== null ? Object.keys(info).map((key) => (
                    <TextField key={key} label={key} value={info[key]}
                        sx={{ mb: 2 }}
                        onChange={(e) => setInfo({ ...info, [key]: e.target.value })} fullWidth />
                )) : CenteredLoading}
                <Button onClick={saveInfo}>Salvar</Button>
            </Box>
        )
    }

    return (
        <Box sx={{ mb: 2, maxWidth: 600, margin: 'auto' }}>
            {info === null ? <CenteredLoading /> : content()}
            <Snackbar open={notificationMessage !== ''} autoHideDuration={6000}
                onClose={() => setNotificationMessage('')} variant={notificationType} message={notificationMessage}
            />
        </Box>
    );
}
