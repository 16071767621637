import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DynamicComponent from './DynamicComponent';
import CenteredLoading from './CenteredLoading';
import ProductSubtitle from './ProductSubtitle';
import ProductSubtitle2 from './ProductSubtitle2';
import ModalAlert from './ModalAlert';
import ApiService from '../utils/ApiService';
import * as Constants from '../utils/constants';
import { removeUnchangedFields } from '../utils/utils';
import ImageUploader from './ImageUploader';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useAuth } from '../contexts/AuthContext';
import Chip from '@mui/material/Chip';
import * as Utils from '../utils/utils';

export default function Summary({ productId, returnFunction, disableBackButton = false }) {
    const [product, setProduct] = useState(null);
    const [alertStrings, setAlertStrings] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [disableProductDialogState, setDisableProductDialogState] = useState(false);
    const [enableProductDialogState, setEnableProductDialogState] = useState(false);
    const [fields, setFields] = useState(null);
    const [showFields, setShowFields] = useState(false);
    const [images, setImages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [issues, setIssues] = useState(null);
    const { checkPrivilege, displaySetting } = useAuth();

    const setErrorMsg = (msg) => {
        setAlertMessage(msg);
    };

    const setErrorMsgSave = (msg) => {
        if (msg.length > 0) {
            setAlertMessage(msg);
        } else {
            setAlertMessage('Erro ao salvar o produto');
        }
    };

    useEffect(() => {
        setShowFields(false);
        if (productId === null)
            return;

        window.document.title = 'Dados do Produto | ' + displaySetting('site_name');
        ApiService.post("/product_data_fields", { "id": productId }).then((response) => {
            window.document.title = `${response.title} | ${displaySetting('site_name')}`;
            setProduct(response);
            setFields(response.fields);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
        });

        ApiService.post('/product_images', { "id": productId }).then((response) => {
            setImages(response.images);
            setIssues(response.issues);
        }).catch((error) => {
            setAlertMessage(error.message);
        });

    }, [productId]);

    useEffect(() => {
        if (fields !== null && Object.keys(fields).length > 0) {
            setShowFields(true);
        }
    }, [fields]);

    const handleRequired = async () => {
        const namesNeeded = [];
        for (const field in fields) {
            if ((fields[field].is_required && fields[field].value === '')) {
                namesNeeded.push(fields[field].name);
            }
        }

        if (namesNeeded.length > 0) {
            setAlertMessage('Os campos abaixo são obrigatórios');
            setAlertStrings(namesNeeded);
            return true;
        } else {
            setAlertMessage('');
            setAlertStrings([]);
            return false;
        }
    };

    async function getUpdatedImages(uploadedImages) {
        if (uploadedImages) {
            let newImages = [];
            let i = 0;
            await images.forEach((image) => {
                if (image instanceof File) {
                    newImages.push({ filepath: uploadedImages[i] });
                    i++;
                } else {
                    newImages.push(image);
                }
            });
            return newImages;
        }
        return null;
    }

    const leavePage = (returnStatus) => {
        returnFunction(returnStatus);
        setLoading(false);
    }

    // TODO: Upload and update images code is repeated in Summary and ImageUploader
    const saveChanges = async () => {
        if (await handleRequired()) {
            return;
        }

        setLoading(true);

        const saveProduct = async () => {
            if (product.fields === fields) {
                return;
            }

            let newProduct = { ...product };
            newProduct.fields = removeUnchangedFields(product.fields, fields);

            await ApiService.post("/save_product_data", newProduct);
        }

        try {
            const newImages = await Utils.toFormData(images);
            let updatedImageArray = images;

            if (newImages && newImages.entries().next().value) {
                let response = await ApiService.postWithFormData('/upload_images', newImages);

                if (response && response.images && response.images.length > 0) {
                    updatedImageArray = await getUpdatedImages(response.images)
                    await ApiService.post('/update_images', { "product_id": productId, "images": updatedImageArray });
                    await saveProduct();
                } else {
                    setAlertMessage('Erro ao atualizar produto. Tente novamente.');
                    setLoading(false);
                    return;
                }
            } else {
                // TODO: Similar to product fields, check if images have changed
                await ApiService.post('/update_images', { "product_id": productId, "images": updatedImageArray });
                await saveProduct();
            }
        } catch (error) {
            setErrorMsgSave(error.message);
            setLoading(false);
            return;
        }

        leavePage(true);
    };

    const handleCloseAlert = () => {
        setAlertMessage('');
        //leavePage(false);
    }

    const handleDisableProduct = async () => {
        setDisableProductDialogState(false);
        try {
            await ApiService.post('/disable_product', { "product_id": productId });
        } catch (error) {
            setErrorMsg(error.message);
            setLoading(false);
            return;
        }
        leavePage(true);
    }

    const handleEnableProduct = async () => {
        setEnableProductDialogState(false);
        try {
            await ApiService.post('/enable_product/', { "product_id": productId });
        } catch (error) {
            setErrorMsg(error.message);
            setLoading(false);
            return;
        }
        leavePage(true);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} isActive={product.is_active} />}

            {product !== null && (
                <div style={{ position: 'relative', textAlign: 'center' }}>
                    <ProductSubtitle2 price={product.price} stock={product.stock}
                        stock_location={product.stock_location}
                        cost={displaySetting('display_product_cost') === true ? product.cost : null}
                        isActive={product.is_active} />
                    {product !== null && !product.is_active && <Chip label="Desativado" color="error"
                        style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }} />}
                </div>
            )}

            {images !== null ?
                <ImageUploader images={images} setImages={setImages} issues={issues} /> : <CenteredLoading />}

            <Grid container justifyContent="center" >
                <Grid item xs={12} sx={{ pt: 3, pb: 5, textAlign: 'center' }}>
                    {showFields ? <DynamicComponent fields={fields} setFields={setFields} step={'summary'} disableSummary={false} />
                        : <CenteredLoading />}
                </Grid>
            </Grid>

            <Box sx={checkPrivilege(Constants.USER_PRIVILEGE.ADMIN) ? { display: 'flex', justifyContent: 'space-between' } : { display: 'flex', justifyContent: 'flex-end' }}>
                {checkPrivilege(Constants.USER_PRIVILEGE.ADMIN) &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {product !== null && product.is_active &&
                            <LoadingButton loading={loading} loadingPosition="start" startIcon={<DeleteIcon />}
                                variant="contained" onClick={() => { setDisableProductDialogState(true); }}
                                sx={{ mt: 3, ml: 1 }} color="error">
                                Desativar
                            </LoadingButton>
                        }
                        {product !== null && !product.is_active &&
                            <LoadingButton loading={loading} loadingPosition="start" startIcon={<DoneIcon />}
                                variant="contained" onClick={() => { setEnableProductDialogState(true); }}
                                sx={{ mt: 3, ml: 1 }} color="success">
                                Reativar
                            </LoadingButton>
                        }
                    </Box>
                }
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {disableBackButton !== true &&
                        <Button onClick={() => { leavePage(false); }} sx={{ mt: 3, ml: 1 }}>
                            Voltar
                        </Button>
                    }
                    {product !== null &&
                        <LoadingButton loading={loading} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" onClick={saveChanges} sx={{ mt: 3, ml: 1 }} >
                            Salvar
                        </LoadingButton>
                    }
                </Box>
            </Box>

            <Dialog open={disableProductDialogState} onClose={() => { setDisableProductDialogState(false); }}>
                <DialogTitle>Desativar Produto</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja realmente desativar o produto? <br />
                        Produtos desativados não serão anunciados.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDisableProductDialogState(false); }} >
                        Cancelar
                    </Button>
                    <Button onClick={handleDisableProduct} color="primary">
                        Desativar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={enableProductDialogState} onClose={() => { setEnableProductDialogState(false); }}>
                <DialogTitle>Reativar Produto</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja realmente reativar o produto? <br />
                        Produtos ativados serão anunciados.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEnableProductDialogState(false); }} >
                        Cancelar
                    </Button>
                    <Button onClick={handleEnableProduct} color="primary">
                        Reativar
                    </Button>
                </DialogActions>
            </Dialog>

            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} alertStrings={alertStrings} closeButton={handleCloseAlert} />
        </React.Fragment >
    );
}
