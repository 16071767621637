import { useState, useEffect } from 'react';
import ApiService from './ApiService';

const usePaginatedData = (endpoint, useAbortController=false, setAlertMessage) => {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [orderActions, setOrderActions] = useState([]);

    let abortController = null;

    const updatePaginationModel = (newPaginationModel) => {
        setPaginationModel({...paginationModel, ...newPaginationModel});
    }

    const fetchData = async () => {
        setLoading(true);

        if (useAbortController) {
            abortController = new AbortController();
        }

        try {
            const response = await ApiService.get(
                endpoint,
                {...paginationModel, page: paginationModel.page + 1},
                true,
                useAbortController ? abortController.signal : undefined
            );

            if ('orders_actions' in response){
                setOrderActions(response.orders_actions);
            }
            setData(response.data);
            setRowCount(response.pagination.totalItems || 0);
            setAlertMessage('');
        } catch (error) {
            if (error.message !== "Request was cancelled" || error.name !== "AbortError") {
                return;
            }
            console.error("Error fetching data:", error);
            setData([]);
            setRowCount(0);
            setAlertMessage(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        return () => {
            if (useAbortController && abortController) {
                abortController.abort();
            }
        };
    }, [paginationModel]);

    const refreshData = () => {
        setPaginationModel({...paginationModel});
    }

    return {
        data,
        orderActions,
        paginationModel,
        updatePaginationModel,
        rowCount,
        loading,
        refreshData
    };
};

export default usePaginatedData;