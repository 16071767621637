import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper'
import Sidebar from '../components/Sidebar';
import TabContext from '@mui/lab/TabContext';
import { useAuth } from '../contexts/AuthContext';
import DisabledProducts from '../components/DisabledProducts';

export default function Manager() {
    const [tab, setTab] = useState('1');
    const defSidebarTitle = 'Gerenciar';
    const { displaySetting } = useAuth();

    const handleChange = (_, newValue) => {
        setTab(newValue);
    }

    React.useEffect(() => {
        window.document.title = defSidebarTitle + ' | ' + displaySetting('site_name');
    }, []);

    return (
        <Sidebar title={defSidebarTitle}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }} >
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Produtos desativados" value="1" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <DisabledProducts />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Sidebar>
    );
}
