import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';
import ModalAlert from '../components/ModalAlert';
import OrderDisplay from '../components/OrderDisplay';
import CenteredLoading from '../components/CenteredLoading';
import { useAuth } from '../contexts/AuthContext';
import ApiService from '../utils/ApiService';

export default function Order() {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [order, setOrder] = useState({});
    const { displaySetting } = useAuth();

    const setErrorMsg = (msg) => {
        setOrder([]);
        setAlertMessage(msg);
    }

    React.useEffect(() => {
        window.document.title = "Venda | " + displaySetting('site_name');
        ApiService.get('/order').then((response) => {
            setOrder(response.order);
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }, []);

    return (
        <Sidebar title='Venda'>
            <Paper
                variant="outlined"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: { xs: 2, md: 3 }, mb: 2,
                }}>
                {Object.keys(order).length > 0 ? < OrderDisplay order={order} /> : <CenteredLoading />}
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar>
    );
}
