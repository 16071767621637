import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import ModalAlert from './ModalAlert';
import ImageUploader from './ImageUploader';
import CenteredLoading from './CenteredLoading';
import ProductSubtitle from './ProductSubtitle';
import ProductSubtitle2 from './ProductSubtitle2';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';
import * as Utils from '../utils/utils';

function ProductImage({ productId, returnFunction }) {
    const [images, setImages] = useState(null);
    const [issues, setIssues] = useState(null);
    const [product, setProduct] = useState({});
    const [alertMessage, setAlertMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { displaySetting } = useAuth();

    useEffect(() => {
        window.document.title = 'Imagem do Produto | ' + displaySetting('site_name');

        ApiService.get('/product', { "id": productId }).then((response) => {
            window.document.title = response.title + ' - Imagens';
            setProduct(response);
        }).catch((error) => {
            setAlertMessage(error.message);
        });

        ApiService.post('/product_images', { "id": productId }).then((response) => {
            setImages(response.images);
            setIssues(response.issues);
        }).catch((error) => {
            setAlertMessage(error.message);
        });

    }, [productId]);

    async function getUpdatedImages(uploadedImages) {
        if (uploadedImages) {
            let newImages = [];
            let i = 0;
            await images.forEach((image) => {
                if (image instanceof File) {
                    newImages.push({ filepath: uploadedImages[i] });
                    i++;
                } else {
                    newImages.push(image);
                }
            });
            return newImages;
        }
        return null;
    }

    const saveChanges = async () => {
        try {
            setLoading(true);
            const newImages = await Utils.toFormData(images);
            let updatedImageArray = images;

            const updateImages = (images) => {
                ApiService.post('/update_images', { "product_id": productId, "images": images }).then((_) => {
                    returnFunction(true);
                    setLoading(false);
                })
            }

            if (newImages && newImages.entries().next().value) {
                ApiService.postWithFormData('/upload_images', newImages).then(async (response) => {
                    updatedImageArray = await getUpdatedImages(response.images)
                    updateImages(updatedImageArray)
                })
            } else {
                updateImages(updatedImageArray)
            }
        } catch (error) {
            setLoading(false);
            setAlertMessage(error.message);
        }
    };

    const handleCloseAlert = () => {
        setAlertMessage('');
        returnFunction(false);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} />}
            {product !== null && <ProductSubtitle2 price={product.price} stock={product.stock}
                stock_location={product.stock_location} cost={product.cost} />}
            <React.Fragment>

                {images !== null ? <ImageUploader images={images} setImages={setImages} issues={issues} /> : <CenteredLoading />}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => { returnFunction(false); }} sx={{ mt: 3, ml: 1 }}>
                        Voltar
                    </Button>
                    <LoadingButton loading={loading} loadingPosition="start" startIcon={<SaveIcon />} variant="contained" onClick={saveChanges} sx={{ mt: 3, ml: 1 }} >
                        Salvar
                    </LoadingButton>
                </Box>
            </React.Fragment>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} closeButton={handleCloseAlert} />
        </React.Fragment >
    )
}

export default ProductImage;

