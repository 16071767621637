import * as React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StoreIcon from '@mui/icons-material/Store';
import { NavLink } from "react-router-dom";
import { GridAddIcon } from '@mui/x-data-grid';
import * as Constants from '../utils/constants';
import TuneIcon from '@mui/icons-material/Tune';
import HistoryIcon from '@mui/icons-material/History';
import MessageIcon from '@mui/icons-material/Message';
import FilterAlt from '@mui/icons-material/FilterAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { useAuth } from '../contexts/AuthContext';

const closeIfMobile = (toggleOpen, drawerVariant) => () => {
    if (drawerVariant === 'temporary')
        toggleOpen();
}

const DrawerItems = ({ toggleOpen, drawerVariant, notificationCount = {}, open }) => {
    const { checkPrivilege, displaySetting } = useAuth();

    const new_item = (icon, text, link, condition) => {
        if (condition)
            return (
                <Tooltip title={text} arrow disableHoverListener={open}>
                    <ListItemButton component={NavLink} to={link} exact="true" onClick={closeIfMobile(toggleOpen, drawerVariant)} key={text}>
                        <ListItemIcon>
                            <Badge badgeContent={link in notificationCount ? notificationCount[link] : 0} color="primary">
                                {icon}
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </Tooltip>
            );
        return null;
    };

    const items = [
        { icon: <SearchIcon />, text: "Buscar", link: "/search", condition: checkPrivilege(Constants.USER_PRIVILEGE.GUEST) },
        { icon: <StoreIcon />, text: "Produtos", link: "/products", condition: checkPrivilege(Constants.USER_PRIVILEGE.GUEST) },
        { icon: <ManageAccountsIcon />, text: "Gerenciar", link: "/manager", condition: checkPrivilege(Constants.USER_PRIVILEGE.ADMIN) },
        { icon: <GridAddIcon />, text: "Criar", link: "/newproduct", condition: displaySetting('create_product_page_enabled') && checkPrivilege(Constants.USER_PRIVILEGE.EDITOR) },
        { icon: <ShoppingCartIcon />, text: "Vendas", link: "/orders", condition: checkPrivilege(Constants.USER_PRIVILEGE.MANAGER) },
        { icon: <MessageIcon />, text: "Mensages", link: "/messages",
            condition: displaySetting('messages_page_enabled') === true && checkPrivilege(Constants.USER_PRIVILEGE.MANAGER) },
        { icon: <FilterAlt />, text: "Mapear", link: "/map",
            condition: displaySetting('map_page_enabled') === true && checkPrivilege(Constants.USER_PRIVILEGE.EDITOR) },
        { icon: <VisibilityIcon />, text: "Revisar", link: "/review",
            condition: displaySetting('review_page_enabled') === true && checkPrivilege(Constants.USER_PRIVILEGE.EDITOR) },
        { icon: <HistoryIcon />, text: "Histórico", link: "/history", condition: checkPrivilege(Constants.USER_PRIVILEGE.MANAGER) },
        { icon: <ReceiptIcon />, text: "Relatório", link: "/report", condition: checkPrivilege(Constants.USER_PRIVILEGE.SUPER_ADMIN) },
        { icon: <TuneIcon />, text: "Config.", link: "/settings", condition: checkPrivilege(Constants.USER_PRIVILEGE.SUPER_ADMIN) },
    ];

    return (
        <React.Fragment>
            <List component="nav">
                {items.map((item) => new_item(item.icon, item.text, item.link, item.condition))}
            </List>
        </React.Fragment>
    );
};

export default DrawerItems;
