import React, { useState, useEffect } from 'react';
import ApiService from '../utils/ApiService';
import CenteredLoading from './CenteredLoading';
import SettingFields from './SettingFields';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function MarketplaceSettings() {
    const [marketplaces, setMarketplaces] = useState(null);
    const [selectedMarketplace, setSelectedMarketplace] = useState(null);
    const [settings, setSettings] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success');

    const errorNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('error');
    }

    const successNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('success');
    }

    useEffect(() => {
        ApiService.get('/marketplaces').then((response) => {
            setMarketplaces(response);
            setSelectedMarketplace(response[0].id);
        }).catch((error) => {
            errorNotification(error.message);
        });
    }, []);

    useEffect(() => {
        if (selectedMarketplace === null)
            return;

        ApiService.get('/marketplace_settings', {'marketplace_id': selectedMarketplace }).then((response) => {
            setSettings(response);
        }).catch((error) => {
            errorNotification(error.message);
        });
    }, [selectedMarketplace]);

    const saveSettings = () => {
        ApiService.post('/save_marketplace_settings', { "marketplace_id": selectedMarketplace, "settings": settings }).then((_) => {
            successNotification('Configurações salvas com sucesso');
        }).catch((error) => {
            errorNotification(error.message);
        });
    }

    const MarketplaceSelect = () => {
        return (
            <FormControl fullWidth>
                <InputLabel>Marketplace</InputLabel>
                <Select
                    value={selectedMarketplace}
                    label="Marketplace"
                    onChange={(event) => setSelectedMarketplace(event.target.value)}
                    sx={{ mb: 5 }}
                >
                    {marketplaces.map((marketplace, index) => {
                        return (<MenuItem key={index} value={marketplace.id}>{marketplace.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
        )
    }

    const content = () => {
        return (
            <Box>
                {SettingFields(settings, setSettings, errorNotification)}
                {settings.length > 0 && <Button onClick={saveSettings}>Salvar</Button>}
            </Box>
        )
    }

    return (
        <Box sx={{ mb: 2, maxWidth: 600, margin: 'auto' }}>
            {marketplaces === null ? <CenteredLoading /> : <MarketplaceSelect />}
            {settings === null ? <CenteredLoading /> : content()}
            <Snackbar open={notificationMessage !== ''} autoHideDuration={6000}
                onClose={() => setNotificationMessage('')} variant={notificationType} message={notificationMessage}
            />
        </Box>
    );
}
