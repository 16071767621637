import React, { useState, useEffect } from 'react';
import log from 'loglevel';
import { Grid } from '@mui/material';
import ProductImage from './ProductImage';
import ProductFields from './ProductFields';
import { useLocation } from 'react-router-dom';
import ProductsDatagrid from './ProductsDatagrid';
import ProductGtin from './ProductGtin';
import Summary from '../components/Summary';
import { useAuth } from '../contexts/AuthContext';

export default function ProductEditor({ products, refreshProducts, setSidebarTitle, parentTitle, updatedColumns = null, showGtinIcon = true, paginationModel,
    updatePaginationModel, rowCount, loading}) {
    const [productId, setProductId] = useState(null);
    const [showProduct, setShowProduct] = useState(null);
    const [showSummary, setShowSummary] = useState(null);
    const [showProductImage, setShowProductImage] = useState(false);
    const [showProductGtin, setShowProductGtin] = useState(false);
    const [field, setField] = useState(null);
    const location = useLocation();
    const { displaySetting } = useAuth();

    const extraColumns = updatedColumns ? updatedColumns : [
        { field: 'title', headerName: 'Título do produto', flex: 50, sortable: true },
        { field: 'gtin', headerName: 'Código de barras', minWidth: 130, flex: 9, sortable: true }
    ];

    const handleCellClick = (e) => {
        log.debug('handleCellClick', e, 'id', e.row.id, 'product', e.row);
        setProductId(e.row.id);
        if (e.field === 'sendImages') {
            setSidebarTitle("Imagem do Produto");
            setShowProductImage(true);
            setField("image");
        }
        else if (e.field === 'gtinIcon') {
            setSidebarTitle("Código de Barras");
            setShowProductGtin(true);
        } else if (e.field == 'editData') {
            setSidebarTitle("Editar Produto");
            setShowProduct(true);
        } else {
            setSidebarTitle("Resumo do Produto");
            setShowSummary(true);
        }
    };

    function closeDetails() {
        setShowSummary(false);
        setShowProduct(false);
        setShowProductImage(false);
        setShowProductGtin(false);
        window.document.title = parentTitle + " | " + displaySetting('site_name');
        setSidebarTitle(parentTitle);

        const url = new URL(window.location);
        url.searchParams.delete('e');
        url.searchParams.delete('id');
    }

    const returnFunction = (reload) => {
        window.history.back();
        if (reload === true) {
            refreshProducts();
        }
    }

    useEffect(() => {
        if (location.search === '') {
            closeDetails();
        }
    }, [location]);

    useEffect(() => {
        const handlePopstate = () => {
            if (productId !== null && (showProduct === true || showProductImage === true || showProductGtin === true || showSummary === true)) {
                closeDetails();
            } else if (productId === null && showProduct === false && showProductImage === false && showProductGtin === false && showSummary === false) {
                window.history.back();
            } else if (productId !== null && (showProduct === false && showProductGtin === false && showProductImage === false && showSummary === false)) {
                if (field === "edit")
                    setShowProduct(true);
                else if (field === "gtin")
                    setShowProductGtin(true);
                else if (field === "image")
                    setShowProductImage(true);
                else
                    setShowSummary(true);
            }
        }

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [showProduct, showProductImage, showProductGtin, showSummary]);

    useEffect(() => {
        const url = new URL(window.location);
        const getId = url.searchParams.get('id');
        const getOption = url.searchParams.get('e');

        if (getId === null && productId !== null && (showSummary === true || showProduct === true || showProductImage === true || showProductGtin === true)) {
            if (showProductImage === true) {
                url.searchParams.set('e', 'image');
            } else if (showProductGtin === true) {
                url.searchParams.set('e', 'gtin');
            } else if (showProduct === true) {
                url.searchParams.set('e', 'edit');
            } else {
                url.searchParams.delete('e');
            }
            url.searchParams.set('id', productId);
            window.history.pushState(null, '', url.toString());
        }
        else if (getId !== null && productId === null) {
            if (getOption === 'image') {
                setShowProductImage(true);
            } else if (getOption === 'gtin') {
                setShowProductGtin(true);
            } else if (getOption === 'edit') {
                setShowProduct(true);
            } else {
                setShowSummary(true);
            }
            setProductId(parseInt(getId));
        }
    }, [showProduct, showProductImage, showProductGtin, productId, showSummary]);

    return (
        <Grid>
            {(showProductImage === true && productId !== null) && <ProductImage productId={productId} returnFunction={returnFunction} />}
            {(showProduct === true && productId !== null) && <ProductFields productId={productId} returnFunction={returnFunction} />}
            {(showSummary === true && productId !== null) && <Summary productId={productId} returnFunction={returnFunction} />}
            {(showProductGtin === true && productId !== null && productId !== undefined) && <ProductGtin productId={productId} returnFunction={returnFunction} />}
            <Grid sx={{ display: (showSummary !== true && showProduct !== true && showProductImage !== true && showProductGtin !== true) ? 'block' : 'none', height: '100%' }}>
                <ProductsDatagrid products={products} extraColumns={extraColumns} showExternalId={true} showGtinIcon={showGtinIcon} handleCellClick={handleCellClick}
                    paginationModel={paginationModel} updatePaginationModel={updatePaginationModel} rowCount={rowCount} loading={loading}/>
            </Grid>
        </Grid>
    );
}
