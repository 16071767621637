import React from 'react';
import Modal from '@mui/material/Modal';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import log from 'loglevel';

const ModalAlert = ({ alertMessage, setAlertMessage, alertStrings = [], alertSeverity = "error", closeButton }) => {
    const [showAlert, setShowAlert] = React.useState(false);

    if (alertMessage.length > 0) {
        log.trace('ModalAlert: [alertMessage]', alertMessage, '[alertStrings]', alertStrings, '[alertSeverity]', alertSeverity);
    }

    const styleBoxAlert = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: 1,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setShowAlert(false);
        setAlertMessage('');
        if (closeButton)
            closeButton();
    };

    React.useEffect(() => {
        setShowAlert(alertMessage.length > 0);
    }, [alertMessage]);

    return (
        <Modal
            open={showAlert}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleBoxAlert}>
                <Alert variant="outlined" severity={alertSeverity} component="h2">
                    <Typography id="modal-modal-title" >
                        {alertMessage}
                    </Typography>
                    {alertStrings.map((string) => (<Typography key={string} component="h2" variant="body2" align="center">{string}</Typography>))}
                </Alert>
                <Button variant="contained" sx={{ mt: 3 }} onClick={handleClose}>Fechar</Button>
            </Box>
        </Modal>
    )
}
export default ModalAlert;