import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function EnvironmentSettings(settings, setSettings, errorMessage, handleColorChange=(()=>{})) {
    if (settings.length == 0)
        return <p>Nenhuma configuração disponível</p>

    return settings.map((setting, index) => {
        let input = null;

        settings.sort((a, b) => {
            if (a.data_type === 'boolean' && b.data_type !== 'boolean') {
                return 1;
            } else if (a.data_type !== 'boolean' && b.data_type === 'boolean') {
                return -1;
            } else {
                return 0;
            }
        });

        if ((setting.data_type === 'text' || setting.data_type === 'int' || setting.data_type === 'numeric') && setting.name !== 'company_logo_path') {
            let onchange = null;
            if (setting.data_type === 'text') {
                onchange = (event) => {
                    let newSettings = [...settings];
                    newSettings[index].value = event.target.value;
                    setSettings(newSettings);
                }
            } else if (setting.data_type === 'int') {
                onchange = (event) => {
                    if (isNaN(parseInt(event.target.value))) {
                        errorMessage('O valor de ' + setting.name + ' deve ser um número inteiro');
                    } else {
                        let newSettings = [...settings];
                        newSettings[index].value = parseInt(event.target.value);
                        setSettings(newSettings);
                    }
                }
            } else if (setting.data_type === 'numeric') {
                onchange = (event) => {

                    // Regex only allows numbers and one dot
                    if (!/^[0-9]*\.?[0-9]*$/.test(event.target.value)) {
                        errorMessage('O valor de ' + setting.name + ' deve ser um número decimal');
                    } else {
                        let newSettings = [...settings];
                        newSettings[index].value = event.target.value;
                        setSettings(newSettings);
                    }
                }
            }

            input = <TextField
                key={index}
                label={setting.name}
                value={setting.value === null ? '' : setting.value}
                onChange={onchange}
                variant="outlined"
                helperText={setting.description}
                error={setting.value === null}
                sx={{ width: '100%' }}
            />

        } else if (setting.data_type === 'boolean') {
            input =
                <FormControl sx={{ width: '100%', border: '1px solid #d3d3d3', borderRadius: '5px', padding: '10px' }}>
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                checked={setting.value ? true : false}
                                onChange={(event) => {
                                    let newSettings = [...settings];
                                    newSettings[index].value = event.target.checked;
                                    setSettings(newSettings);
                                }}
                            />
                        }
                        label={setting.name}
                    />
                    <FormHelperText>{setting.description}</FormHelperText>
                </FormControl>
        } else if (setting.data_type === 'hex_color'){
            input =
                <FormControl sx={{ width: '100%', border: '1px solid #d3d3d3', borderRadius: '5px', padding: '15px' }}>
                    <FormControlLabel
                        key={index}
                        control={
                            <input
                                style={{width: '30px', height: '30px', marginRight: '10px', marginLeft: '10px', boxSizing: 'border-box'}}
                                type="color"
                                value={setting.value}
                                onChange={(event) => {
                                    let newSettings = [...settings];
                                    newSettings[index].value = event.target.value;
                                    setSettings(newSettings);

                                    handleColorChange(event.target.value, setting.name);
                                }}
                            />
                        }
                        label={setting.name}
                    />
                    <FormHelperText>{setting.description}</FormHelperText>
                </FormControl>
        }

        return (
            <Grid container key={index} spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                    {input}
                </Grid>
            </Grid>
        )
    });
}
