import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useAuth } from '../contexts/AuthContext';

export default function LineButtons({ options, handleButtonClick, disabled = false, variant = "text" }) {
    const { checkPrivilege } = useAuth();

    if (!options || !Array.isArray(options)) {
        return null;
    }

    for (const option of options) {
        if (option.privilege_level && !checkPrivilege(option.privilege_level)) {
            option.label = '';
        }
    }
    return (
        <Stack spacing={2} direction="row">
            {options.map((option, _) => (
                <Button key={option.id} variant={variant} disabled={disabled} onClick={() => handleButtonClick(option.id)}>{option.label}</Button>
            ))}
        </Stack>
    );
}