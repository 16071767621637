import { Grid, Typography } from "@mui/material";

function TextBorder({ index, children, label }) {
    return (
        <Grid index={index}
            sx={{
                border: '1px solid #ccc',
                position: 'relative',
                borderRadius: '5px',
                '&:hover': {
                    borderColor: 'black',
                    transition: 'border-color 0.3s ease',
                },
                p:2,
            }}>
            <Typography
                variant="subtitle"
                sx={{
                    position: 'absolute',
                    top: '-10px',
                    left: '10px',
                    backgroundColor: 'white',
                    padding: '0 5px',
                    fontSize: '0.82rem',
                    lineHeight: 1.66,
                    color: '#6B6B6B',
                }}
            > {label} </Typography>
            <Grid sx={{mt:1}}>
                {children}
            </Grid>
        </Grid>
    );
}

export default TextBorder;