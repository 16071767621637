import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import log from 'loglevel';
import ModelAlert from './ModalAlert';
import ProductStepper from './ProductStepper';
import CenteredLoading from './CenteredLoading';
import ProductSubtitle from './ProductSubtitle';
import ProductSubtitle2 from './ProductSubtitle2';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function ProductGtin({ productId, returnFunction }) {
    const [product, setProduct] = useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [steps,] = useState(['other']);
    const [stepTitles,] = useState(['Outros']);
    const { displaySetting } = useAuth();

    const saveChanges = async (newProduct) => {
        log.debug('ProductGtin::saveProducts: [product]', newProduct);

        ApiService.post("/update_gtin", { product_id: productId, gtin: newProduct.fields.gtin.value }).then((_) => {
            returnFunction(true);
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    };

    useEffect(() => {
        window.document.title = 'Dados do Produto | ' + displaySetting('site_name');

        ApiService.get("/product", { "id": productId }).then((response) => {
            prepareProduct(response);
        }).catch((error) => {
            // TODO: potential infinite loop when api call fails
            setAlertMessage(error.message);
        });
    }, []);

    const prepareProduct = (productNew) => {
        let gtinField = {
            name: 'GTIN',
            step: 'other',
            value: productNew.gtin === null ? '' : productNew.gtin,
            value_type: 'numeric',
            can_be_edited: true,
            needs_edit: false,
            column_name: 'gtin',
            is_required: false,
            is_extra: false,
            child: null,
            parent: null,
            options: null
        }

        setProduct({
            ...productNew,
            fields: { gtin: gtinField }
        })

        window.document.title = `${productNew.title} | ${displaySetting('site_name')}`;
        setAlertMessage("");
    }

    const handleCloseAlert = () => {
        setAlertMessage('');
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} />}
            {product !== null && <ProductSubtitle2 price={product.price} stock={product.stock}
                stock_location={product.stock_location} cost={product.cost} />}

            {product !== null ?
                <ProductStepper product={product} setProduct={setProduct} steps={steps} stepTitles={stepTitles} saveChanges={saveChanges} returnFunction={returnFunction} /> :
                <CenteredLoading />
            }
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} closeButton={handleCloseAlert} />
        </React.Fragment>
    );
}
