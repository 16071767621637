import React from 'react';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import LogoutIcon from '@mui/icons-material/Logout';
import UserIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link as RouterLink } from 'react-router-dom'
import DrawerItems from './DrawerItems';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useSidebar } from '../contexts/SidebarContext';
import { useTheme } from '@mui/material/styles';
import * as Constants from '../utils/constants';

const drawerWidth = 180;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
            }),
        },
    }),
);

function Sidebar({ children, title = '' }) {
    const { user, loading, changeClient, displaySetting } = useAuth()
    const navigate = useNavigate()

    React.useEffect(() => {
        if (!loading && !user) {
            navigate('/login')
        }

    }, [user, loading, navigate]);

    const { open, toggleOpen, drawerVariant } = useSidebar();

    const ClientSelector = ({ user }) => {
        const theme = useTheme();

        const handleChange = (event) => {
            // find the index of where obj.client == event.target.value
            const index = user.clients.findIndex(obj => obj.client === event.target.value);

            if (index !== -1) {
                changeClient(index);

                const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
                window.history.replaceState({}, document.title, url);

                window.location.reload();
            }

        };

        if (user.clients.length === 1) {
            return (
                <Typography
                    sx={{
                        mr: 1.5,
                        color: theme.palette.primary.contrastText,
                    }}
                >
                    {user.clients[0].display_name? user.clients[0].display_name : user.clients[0].client}
                </Typography>
            );
        }

        return (
            <FormControl
                sx={{
                    mr: 1.5,
                    minWidth: 120,
                    '& .MuiInputLabel-root': {
                        color: theme.palette.primary.contrastText,
                        '&.Mui-focused': {
                            color: theme.palette.primary.contrastText
                        }
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.secondary.main,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.secondary.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.contrastText,
                        },
                    },
                    '& .MuiSelect-icon': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiMenuItem-root': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiSelect-select': {
                        color: theme.palette.primary.contrastText,
                    }
                }}
                size="small"
            >
                <InputLabel>Empresa</InputLabel>
                <Select
                    value={user.selectedClient.client}
                    onChange={handleChange}
                    input={<OutlinedInput label="Empresa" />}
                >
                    {user.clients.map((client, index) => (
                        <MenuItem
                            key={index}
                            value={client.client}
                        >
                            {client.display_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return user && (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleOpen} sx={{
                        marginRight: window.innerWidth > 600 ? '36px' : '0px',
                        ...(open && { display: 'none' }),
                    }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant={window.innerWidth > 600 ? "h6" : ""} color="inherit" noWrap sx={{ flexGrow: 1 }} >
                        {title}
                    </Typography>
                    < ClientSelector user={user} />
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'right', marginRight: '5px' }}>
                        <UserIcon sx={{ width: 15, height: 15 }} />
                        <Typography component="h1" color="inherit" noWrap sx={{ marginLeft: '5px' }}>
                            {user.username}
                        </Typography>
                    </Box>
                    <IconButton color="inherit" component={RouterLink} to="/logout">
                        < LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant={drawerVariant} open={open} ModalProps={{ keepMounted: true }} onClose={toggleOpen}>
                <Toolbar sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: [2],
                }}
                >
                    {displaySetting('company_logo_path') && (
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img
                                src={Constants.IMAGES_ENDPOINT + displaySetting('company_logo_path')}
                                style={{ width: 'auto', height: '50px', objectFit: 'flex' }}
                            />
                        </div>
                    )}
                    <IconButton onClick={toggleOpen} style={{ position: 'absolute', right: 0 }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <DrawerItems toggleOpen={toggleOpen} drawerVariant={drawerVariant} open={open} />
            </Drawer>
            <Box
                component={window.innerWidth > 600 ? 'main' : 'main_mobile'}
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Box component={window.innerWidth > 600 ? 'content' : 'content_mobile'}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

export default Sidebar;
