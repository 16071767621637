import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper'
import Sidebar from '../components/Sidebar';
import TabContext from '@mui/lab/TabContext';
import { useAuth } from '../contexts/AuthContext';

import CompanyInfo from '../components/CompanyInfo';
import MarketplaceSettings from '../components/MarketplaceSettings';
import EnvironmentSettings from '../components/EnvironmentSettings';
import DisplaySettings from '../components/DisplaySettings';

export default function Settings() {
    const [tab, setTab] = useState('1');
    const { displaySetting } = useAuth();

    const handleChange = (_, newValue) => {
        setTab(newValue);
    }

    useEffect(() => {
        window.document.title = "Configurações | " + displaySetting('site_name');
    }, []);

    return (
        <Sidebar title={"Configurações"}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }} >
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Configurações de Marketplace" value="1" />
                            <Tab label="Configurações do Ambiente" value="2" />
                            <Tab label="Configurações de Exibição" value="3" />
                            <Tab label="Dados do Cliente" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {MarketplaceSettings()}
                    </TabPanel>
                    <TabPanel value="2">
                        {EnvironmentSettings()}
                    </TabPanel>
                    <TabPanel value="3">
                        {DisplaySettings()}
                    </TabPanel>
                    <TabPanel value="4">
                        {CompanyInfo()}
                    </TabPanel>
                </TabContext>
            </Paper>
        </Sidebar>
    );
}
