// Returns a Circular Progress Bar within a grid container, making sure the circular progress bar is centered
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CenteredLoading = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px" // Adjust the height as needed
        >
            <CircularProgress />
        </Box>
    );
};

export default CenteredLoading;
