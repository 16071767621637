import { useCallback } from 'react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import DoneIcon from '@mui/icons-material/Done';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import log from 'loglevel';
import { useTheme } from '@mui/material/styles';
import * as Constants from '../utils/constants';

const MessageContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: '8px',
}));

const Timestamp = styled(Typography)({
    marginTop: '0.3rem',
    fontSize: '0.7rem',
});

const MessengerContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    maxHeight: '70vh',
    overflowY: 'auto',
});

const iconMap = {
    send: <DoneIcon color='primary' sx={{ width: '16px' }} aria-label='mensagem enviada' />,
    scheduled: <ScheduleSendIcon color='primary' sx={{ width: '14px' }} aria-label='mensages agendada' />,
    failed: <SmsFailedIcon color='error' sx={{ width: '14px' }} aria-label='mensagem falhou!' />,
    default: <ScheduleIcon color='primary' sx={{ width: '14px' }} aria-label='erro ao enviar mensagem' />
};

export const MessageStatusIcon = React.memo(function MessageStatusIcon({ status = 'default' }) {
    const icon = iconMap[status] ?? iconMap.default;
    return icon;
});
MessageStatusIcon.displayName = 'MessageStatusIcon';

const Message = ({ fromBuyer, content, timestamp, status, id, handleResendMessage }) => {
    return (
        <MessageContainer elevation={3}
            sx={{
                alignSelf: !fromBuyer ? 'flex-end' : 'flex-start',
                backgroundColor: fromBuyer ? '#e1f5fe' : '',
                minWidth: '120px',
                maxWidth: '90%',
            }}
        >
            <Grid item xs={12} md={12} lg={12} >
                <Typography >{content}</Typography>
            </Grid>
            <Grid container >
                <Grid item xs={10} md={10} lg={10} >
                    <Timestamp>{timestamp}</Timestamp>
                </Grid>

                <Grid item xs={2} md={2} lg={2}  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <MessageStatusIcon status={status} />
                    </Box>
                </Grid>
            </Grid>
            {status === 'failed' && <Grid item xs={12} md={12} lg={12} >
                <Button size="small" color="primary" id={id} onClick={handleResendMessage} sx={{ height: 2 }}>Reenviar</Button>
            </Grid>}
        </MessageContainer >
    );
};

export const Messenger = ({ messages, newMessagesQueue, handleResendMessage }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [newMessagesQueue]);

    log.trace("Messenger [messages]", messages);

    return (
        <MessengerContainer elevation={3} ref={containerRef}>
            {[...messages, ...newMessagesQueue].map((message, index) => (
                <Message
                    key={index}
                    id={index}
                    {...message}
                    handleResendMessage={handleResendMessage}
                />
            ))}
        </MessengerContainer>
    );
};

Messenger.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            fromBuyer: PropTypes.bool.isRequired,
            content: PropTypes.string.isRequired,
            timestamp: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            // messageId: PropTypes.number.isRequired, // needed?
        })
    ).isRequired,
    newMessagesQueue: PropTypes.arrayOf(
        PropTypes.shape({
            fromBuyer: PropTypes.bool.isRequired,
            content: PropTypes.string.isRequired,
            timestamp: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            // messageId: PropTypes.number.isRequired, // needed?
        })
    ).isRequired,
    handleResendMessage: PropTypes.func.isRequired,
};

export const MessageInput = ({ handleNewMessage, newMessage, setNewMessage }) => {
    const theme = useTheme();
    const sendMessage = useCallback(() => {
        if (newMessage.trim() !== '') {
            log.trace("MessageInput [newMessage]", newMessage);
            handleNewMessage(newMessage);
            setNewMessage('');
        }
    }, [handleNewMessage, newMessage]);

    const handleChange = useCallback((e) => {
        setNewMessage(e.target.value);
    }, [setNewMessage]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey && newMessage.trim() !== '') {
            e.preventDefault();
            sendMessage();
        }
    }, [sendMessage]);

    return ( // TODO: add a button to receive that encoment
        <Grid container sx={{ borderTop: 1, borderColor: theme.palette.primary.main }} alignItems="center">
            <InputBase
                autoFocus
                multiline={true}
                rows={4}
                value={newMessage}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Mensagem"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={sendMessage}>
                <SendIcon color='primary' />
            </IconButton>
        </Grid>
    );
}

export const MessengerHeader = ({ chat }) => {
    const theme = useTheme();

    const StyledBox = styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }));

    const StyledButton = styled(Button)(() => ({
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
    }));

    return (
        <StyledBox>
            <Grid container direction='row'>
                <Grid item xs={12} md={12} lg={12} alignItems={'center'}>
                    <Typography variant='h5'>{chat.productsStr} - {chat.marketplaceName}</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ borderTop: 1, borderColor: theme.palette.primary.light }}>
                    <Grid container>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography variant='h6'>Comprador: {chat.order.buyerName}</Typography>
                            <Typography variant='h6'>
                                Valor: {chat.order.salePrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                            <Typography variant='h6'>ID marketplace: {chat.order.externalOrderId}</Typography>
                            <Typography variant='h6'>Data da venda: {chat.order.saleTimestamp}</Typography>
                        </Grid>
                        <Grid container item xs={6} md={6} lg={6} direction='column' sx={{ mt: 1, alignItems: 'flex-end' }}>
                            <a href={chat.listingUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <StyledButton variant="contained" color="primary" style={{ marginBottom: '8px' }}>
                                    Anúncio
                                </StyledButton>
                            </a>
                            <a href={Constants.HTTP_URL + '/order/' + chat.orderId} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <StyledButton variant="contained" color="primary">
                                    Venda {chat.orderId}
                                </StyledButton>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

MessengerHeader.propTypes = {
    chat: PropTypes.shape({
        productsStr: PropTypes.string.isRequired,
        marketplaceName: PropTypes.string.isRequired,
        order: PropTypes.shape({
            buyerName: PropTypes.string.isRequired,
        }).isRequired,
        listingUrl: PropTypes.string.isRequired,
    }).isRequired,
};