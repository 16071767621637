import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton'; import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as Constants from '../utils/constants';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';

const ImageUploader = ({ images, setImages, issues }) => {
    const fileInputRef = useRef(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showError, setShowError] = useState(false);
    const { displaySetting } = useAuth();
    let images_error = 'Algumas das imagens que você tentou enviar não foram aceitas pois não atingem o tamanho mínimo ';
    images_error += `de ${displaySetting('min_image_width')}x${displaySetting('min_image_height')}. Por favor, envie imagens maiores.`;

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowLeft':
                    handlePrev();
                    break;
                case 'ArrowRight':
                    handleNext();
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, images.length]);

    useEffect(() => {
        setCurrentIndex(images.indexOf(selectedImage));
    }, [selectedImage]);

    useEffect(() => {
        document.addEventListener('paste', handleFileInputChange);
        return () => {
            document.removeEventListener('paste', handleFileInputChange);
        };
    }, []);

    const loadFile = (file) => {
        const image = new Image();
        image.onload = function () {
            if (this.naturalWidth >= displaySetting('min_image_width') && this.naturalHeight >= displaySetting('min_image_height')) {
                setImages(prevImages => [...prevImages, file]);
            }
            else {
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 10000);
            }
        }
        image.src = URL.createObjectURL(file);
    }

    const handleFileInputChange = (e) => {
        setShowError(false);
        if (e instanceof ClipboardEvent) {
            const items = e.clipboardData.items;
            for (let item of items) {
                const file = item.getAsFile();
                if (item.type.startsWith('image/')) {
                    loadFile(file);
                }
            }
        } else {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                if (e.target.files[i].type.startsWith('image/')) {
                    loadFile(file);
                }
            }
        }
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenDialog(true);
    };

    const handleDeleteImage = (file) => {
        const updatedImages = images.filter((f) => f !== file);
        setImages(updatedImages);
        setOpenDialog(false);
    };

    const handlePrev = () => {
        if (currentIndex !== -1) {
            const previousIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
            const previousImage = images[previousIndex];
            setSelectedImage(previousImage);
        }
    };

    const handleNext = () => {
        if (currentIndex !== -1) {
            const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
            const nextImage = images[nextIndex];
            setSelectedImage(nextImage);
        }
    };

    return (
        <React.Fragment>
            {issues && issues.length > 0 &&
                issues.map((issue, index) => (
                    <Alert key={index} severity="error" sx={{ borderRadius: 1.5, mt: 1 }}>
                        {issue}
                    </Alert>
                ))
            }
            {showError && <Alert severity="error" sx={{ borderRadius: 1.5, mt: 1 }} onClose={() => { setShowError(false) }}>
                {images_error}
            </Alert>}
            <Grid container
                sx={{
                    border: '1px solid #ccc',
                    padding: '16px 0px 6px 16px', /* top, right, bot, left */
                    position: 'relative',
                    borderRadius: '5px',
                    '&:hover': {
                        borderColor: 'black',
                        transition: 'border-color 0.3s ease',
                    },
                    mt: 2
                }}>
                <Grid item xs={12} md={8} lg={9.5}>
                    <Typography
                        variant="subtitle"
                        sx={{
                            position: 'absolute',
                            top: '-10px',
                            left: '10px',
                            backgroundColor: 'white',
                            padding: '0 5px',
                            fontSize: '0.82rem',
                            lineHeight: 1.66,
                            color: '#6B6B6B',
                        }}
                    >
                        Imagens
                    </Typography>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                        multiple
                        accept="image/*"
                    />
                    <DndProvider backend={HTML5Backend}>
                        <ImageGallery
                            images={images}
                            setImages={setImages}
                            onImageClick={handleImageClick}
                            onDelete={handleDeleteImage}
                        />
                    </DndProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        sx={{ width: '210px', mb: 1, ml: 0.5 }}
                        onClick={() => fileInputRef.current.click()}
                    >
                        Enviar imagens
                    </Button>
                    <ImageModal open={openDialog} length={images.length} image={selectedImage} onClose={() => setOpenDialog(false)} onNext={handleNext} onPrev={handlePrev} />
                    <Button
                        variant="contained"
                        component="label"
                        disabled={images.length <= 0}
                        startIcon={<CancelIcon />}
                        color="error" onClick={() => setImages([])}
                        sx={{ width: '210px', mb: 1, ml: 0.5 }}>
                        Remover imagens
                    </Button>
                </Grid>
            </Grid >
        </React.Fragment>
    );
};

const getImageUrl = (image) => {
    // if image has filepath property, it's from database
    if (image === null || image === undefined) {
        return '';
    } else if (typeof image === 'object' && image.filepath) {
        return Constants.IMAGES_ENDPOINT + image.filepath;
    } else if (typeof image === 'object' && image.name) {
        // Is a file object
        return URL.createObjectURL(image);
    }

    return '';
}

const DraggableImage = ({ image, index, onImageClick, onDelete, moveImage }) => {
    const [, ref] = useDrag({
        type: 'IMAGE',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'IMAGE',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <Box ref={(node) => ref(drop(node))}
            style={{
                position: 'relative', marginRight: '10px', marginBottom: '10px', cursor: 'grab',
                border: image.has_issues ? '2px solid red' : 'none'
            }}
        >
            <img
                src={getImageUrl(image)}
                alt={`uID ${index + 1}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                onClick={() => onImageClick(image)}
            />
            <IconButton
                style={{
                    position: 'absolute', top: '5px', right: '5px', color: 'white', backgroundColor: 'red',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}
                onClick={() => onDelete(image)}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

const ImageGallery = ({ images, setImages, onImageClick, onDelete }) => {
    const moveImage = (fromIndex, toIndex) => {
        const newImages = [...images];
        newImages.splice(toIndex, 0, newImages.splice(fromIndex, 1)[0]);
        setImages(newImages);
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', minHeight: '50px' }}>
            {images.map((image, index) => (
                <DraggableImage
                    image={image}
                    index={index}
                    onImageClick={onImageClick}
                    onDelete={onDelete}
                    moveImage={moveImage}
                    key={index}
                />
            ))}
        </div>
    );
};

const ImageModal = ({ open, image, length, onClose, onNext, onPrev }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent style={{ display: 'flex' }}>
                {length > 1 &&
                    <IconButton
                        style={{ position: 'absolute', top: '50%', left: '0%' }}
                        aria-label="Previous"
                        onClick={onPrev}
                    >
                        <ArrowCircleLeftIcon style={{ fontSize: 35 }} />
                    </IconButton>}
                <img
                    src={getImageUrl(image)}
                    alt="Maximizar imagem"
                    style={{ maxWidth: '100%', objectFit: 'contain', width: '800px' }}
                />
                {length > 1 &&
                    <IconButton
                        style={{ position: 'absolute', top: '50%', right: '0%' }}
                        aria-label="Next"
                        onClick={onNext}
                    >
                        <ArrowCircleRightIcon style={{ fontSize: 35 }} />
                    </IconButton>}
            </DialogContent>
        </Dialog>
    );
};

export default ImageUploader;