import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar';

const NotFoundPage = () => {
    return (
        <Sidebar>
            <Container>
                <Paper variant="outlined" sx={{
                    p: { xs: 2, md: 3 }, mb: 2
                }}>
                    <Typography component="h1" variant="h4" align="center" mb={2}>
                    Página não encontrada!
                    </Typography>
                    <Typography component="h2" variant="h5" align="center">
                    A página que você está procurando não existe.
                    </Typography>
                </Paper>
            </Container>
        </Sidebar>
    );
}

export default NotFoundPage;
