// datagrid
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import ApiService from '../utils/ApiService';
import Snackbar from '@mui/material/Snackbar';
import CenteredLoading from './CenteredLoading';
import { useNavigate } from 'react-router-dom'

export default function DisabledProducts() {
    const [products, setProducts] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success');
    const navigate = useNavigate()

    const handleCellClick = (e) => {
        if (e.row.id !== null) {
            navigate('/products?id=' + e.row.id);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'title', headerName: 'Title', flex: 3 },
        {
            field: 'button',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleEnable(params)}
                >
                    Reativar
                </Button>
            ),
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            sortable: false,
        },
    ];

    const handleEnable = (event) => {
        ApiService.post(`/enable_product/`, { product_id: event.row.id }).then(() => {
            successMsg('Produto habilitado com sucesso!');
            deleteProduct(event.row.id);
        }).catch((_) => {
            errorMsg("Erro ao habilitar produto!");
        });
    };

    const fetchProducts = () => {
        setProducts([]);
        ApiService.get("/disabled_products").then((response) => {
            setProducts(response.data);
        }).catch((error) => {
            errorMsg(error.message);
        });
    }

    const errorMsg = (error) => {
        setNotificationMessage(error);
        setNotificationType('error');
    }

    const successMsg = (message) => {
        setNotificationMessage(message);
        setNotificationType('success');
    }

    const deleteProduct = (id) => {
        setProducts(products.filter((product) => product.id !== id));
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            {products !== null ? <DataGrid rows={products} columns={columns} pageSize={5} disableRowSelectionOnClick
                autoHeight={true} localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, footerRowSelected: () => '' }}
                slotProps={{
                    pagination: {
                        labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
                        labelRowsPerPage: 'Produtos por página',
                    }
                }}
                onCellClick={handleCellClick}
            /> : <CenteredLoading />}
            <Snackbar open={notificationMessage !== ''} autoHideDuration={6000}
                onClose={() => setNotificationMessage('')} variant={notificationType} message={notificationMessage} />
        </React.Fragment>
    );
}
