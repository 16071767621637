import React, { useState, useEffect } from 'react';
import log from 'loglevel';
import { v4 as uuid4 } from 'uuid';
import { CssBaseline } from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { Messenger, MessageInput, MessengerHeader } from '../components/Messenger';
import Sidebar from '../components/Sidebar'
import ModalAlert from '../components/ModalAlert';
import ModalDialog from '../components/ModalDialog';
import * as Constants from '../utils/constants';
import { formatDateWithoutTimezone } from '../utils/utils';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function Messages() {
    const [chats, setChats] = useState([])
    const [alertMessage, setAlertMessage] = useState('');
    const [messages, setMessages] = useState(null);
    const [chat, setChat] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [isSendingReply, setIsSendingReply] = useState(false);
    const { displaySetting } = useAuth();
    const [newMessage, setNewMessage] = useState('')
    const [newMessagesQueue, setNewMessagesQueue] = useState([]);
    const [statusQueue, setStatusQueue] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState([]);
    const [pageModel, setPageModel] = useState({ pageSize: 10, page: 0 });

    // TODO: move datagrid to a component
    const [columns] = useState([
        {
            field: 'marketplaceId',
            headerName: '',
            sortable: false,
            width: 60,
            align: 'center',
            renderCell: (params) => (<img src={Constants.ICON_DIR + params.value + ".ico"} width="30" />),
            stopClickPropagation: true,
        },
        { field: 'lastMsgTimestamp', headerName: 'Data', minWidth: 90, maxWidth: 120, flex: 20, sortable: true },
        { field: 'productsStr', headerName: 'Produtos', flex: 40, sortable: false },
        { field: 'buyer', headerName: 'Comprador', minWidth: 100, flex: 12, sortable: true },
        { field: 'status', headerName: 'Status', minWidth: 80, flex: 9, sortable: true },
    ]);

    const setErrorMsg = (msg) => {
        log.error(msg);
        setChats([])
        setAlertMessage(msg);
    }

    const apiCallChats = () => {
        ApiService.post("/chats", { status: "unread" }).then((response) => {
            setChats(response.chats);
            setAlertMessage("");
        }).catch((error) => {
            setErrorMsg(error.message);
        });
    }

    const apiReplyChat = async (reply) => {
        try {
            await ApiService.post("/reply_chat", { chatId: chat.id, content: reply });
            return true;
        } catch(error) {
            setErrorMsg(error.message);
            return false;
        }
    }

    useEffect(() => {
        window.document.title = "Mensagens | " + displaySetting('site_name');
        apiCallChats();
    }, []);

    useEffect(() => {
        if (messages) {
            log.debug("Messages useEffect messageRespond: ", messages);
        }

        if (chat.id !== undefined) {
            ApiService.post('/messages', { chatId: chat.id }).then((response) => {
                setMessages(response.messages);
                setAlertMessage("");
                setNewMessagesQueue([]);
                setNewMessage('');
                setShowMessage(true);
            }).catch((error) => {
                setErrorMsg(error.message);
            });
        }
    }, [chat]);

    useEffect(() => {
        if (!showMessage) {
            log.debug("Chats useEffect [showMessage]", showMessage);
            apiCallChats();
        }
    }, [showMessage]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isSendingReply !== false || newMessage !== '') {
                const confirmationMessage = 'Existem mensagens sendo enviadas. Tem certeza que deseja sair?';
                event.returnValue = confirmationMessage; // Standard for most browsers
                return confirmationMessage; // For some older browsers
            }
        };

        const handlePopstate = () => {
            if (isSendingReply !== false || newMessage !== '') {
                setDialogTitle('Menagem não enviada');
                setDialogMessage('Existem mensagens sendo enviadas. Aguarde ou cancele o envio?');
                setOpenDialog(true);
            } else {
                window.history.back();
            }
        };

        if (showMessage === true && (isSendingReply === true || newMessage !== '')) {
            window.history.pushState(null, '', window.location.href);
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isSendingReply, showMessage, newMessage]);

    useEffect(() => {
        if (newMessagesQueue.length > 0) {
            setIsSendingReply(true);

            for (let i = 0; i < newMessagesQueue.length; i++) {
                if (newMessagesQueue[i].status === 'new') {
                    handleSendMessage(newMessagesQueue[i]);
                } else if (newMessagesQueue[i].status === 'scheduled') {
                    setMessages([...messages, newMessagesQueue[i]]);
                    setNewMessagesQueue((prevMessages) => prevMessages.filter((message) => message.id !== newMessagesQueue[i].id));
                }
            }
        } else {
            setIsSendingReply(false);
        }
    }, [newMessagesQueue]);

    useEffect(() => {
        for (let i = 0; i < statusQueue.length; i++) {
            updateMessageStatus(statusQueue[i].id, statusQueue[i].status, newMessagesQueue);
            setStatusQueue((prevStatusQueue) => prevStatusQueue.filter((item) => item.id !== statusQueue[i].id));
        }
    }, [statusQueue]);

    const messagerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 1,
        bgcolor: 'background.paper',
        boxShadow: 24,
        width: '90%',
        height: '95%',
        maxWidth: 900,
    };

    const handleNewMessage = async (newMessage) => {
        if (newMessage === '') {
            return;
        }

        const newMessageObj = createNewMessage(newMessage);
        setNewMessagesQueue([...newMessagesQueue, newMessageObj]);
    };

    const updateMessageStatus = (messageId, status, updatedMessages) => {
        // log.debug("updateMessageStatus [messageId]", messageId, "[status]", status);

        const existingStatusIndex = statusQueue.findIndex((item) => item.id === messageId);

        if (existingStatusIndex !== -1) {
            // If the status for the message is already in the queue, update it
            const updatedStatusQueue = [...statusQueue];
            updatedStatusQueue[existingStatusIndex].status = status;
            setStatusQueue(updatedStatusQueue);
        } else {
            // If the status is not in the queue, add it
            setStatusQueue((prevStatusQueue) => [...prevStatusQueue, { id: messageId, status }]);
        }

        const updatedMessagesCopy = updatedMessages.map((message) => {
            if (message.id === messageId) {
                return { ...message, status };
            }
            return message;
        });

        // log.debug("updateMessageStatus [updatedMessagesCopy]", updatedMessagesCopy);
        setNewMessagesQueue(updatedMessagesCopy);
    };

    const handleSendMessage = async (message) => {
        setStatusQueue([...statusQueue, { id: message.id, status: 'loading' }]);

        try {
            const sent = await apiReplyChat(message.content);
            log.debug("handleSendMessage [sent]", sent);
            if (sent) {
                setStatusQueue([...statusQueue, { id: message.id, status: 'scheduled' }]);
            } else {
                setStatusQueue([...statusQueue, { id: message.id, status: 'failed' }]);
            }
        } catch (error) {
            log.error("Error sending message:", error);
            return false;
        }
    };

    const handleResendMessage = async (event) => {
        if (event.target === undefined || event.target.id === undefined) {
            return;
        }

        setStatusQueue([...statusQueue, { id: event.target.id, status: 'new' }]);

        let messageResend = {};
        for (let i = 0; i < newMessagesQueue.length; i++) {
            if (newMessagesQueue[i].id === event.target.id) {
                messageResend = newMessagesQueue[i];
            }
        }

        handleSendMessage(messageResend);
    };

    const createNewMessage = (content) => {
        return {
            id: uuid4(),
            fromBuyer: false,
            content: content,
            timestamp: formatDateWithoutTimezone,
            status: 'new'
        };
    };

    const handleConfirmation = (isConfirmed) => {
        log.trace('handleConfirmation [isConfirmed]', isConfirmed);
        if (isConfirmed === true) {
            log.trace('handleConfirmation [isSendingReply]', isSendingReply, 'newMessagesQueue]', newMessagesQueue);
            setShowMessage(false);
            setNewMessagesQueue([]);
            setNewMessage('');
            setIsSendingReply(false);
        }
    };

    const handleBackdropClick = (event) => {
        log.trace('handleBackdropClick [isSendingReply]', isSendingReply, 'newMessagesQueue]', newMessagesQueue);
        if (!isSendingReply && newMessage === '') {
            setShowMessage(false);
            setOpenDialog(false);
        } else {
            setDialogTitle('Menagem não enviada');
            setDialogMessage('Existem mensagens sendo enviadas. Aguarde ou cancele o envio?');
            setOpenDialog(true);
        }
        event.stopPropagation();
    };

    const handleCellKeyDown = (params, event) => {
        log.trace('handleCellKeyDown [key]', event.key, '[params]', params, '[event]', event);
        if (event.key === 'Enter') {
            event.stopPropagation(); // Prevent any default action.
            setSelectionModel([params.id]);
            setChat(params.row);
        }

        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
            if (event.key === 'ArrowDown') {
                if (params.id < chats.length && params.id < (pageModel.pageSize * (pageModel.page + 1)))
                    setSelectionModel([params.id + 1]);
            } else if (event.key === 'ArrowUp') {
                if (params.id > 1 && params.id > (pageModel.pageSize * pageModel.page + 1))
                    setSelectionModel([params.id - 1]);
            }
        }

        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            event.preventDefault();
            if (chats.length !== 0) {
                if (event.key === 'ArrowRight' && chats.length > (pageModel.pageSize * (pageModel.page + 1))) {
                    setPageModel({ ...pageModel, page: pageModel.page + 1 });
                } else if (event.key === 'ArrowLeft' && pageModel.page > 0) {
                    setPageModel({ ...pageModel, page: pageModel.page - 1 });
                }
            }
        }

        if (event.key === 'Home') {
            event.preventDefault();
            setPageModel({ ...pageModel, page: 0 });
        }

        if (event.key === 'End') {
            event.preventDefault();
            if (pageModel.pageSize !== 0) {
                setPageModel({ ...pageModel, page: Math.floor(chats.length / pageModel.pageSize) });
            }
        }

        if (event.key === 'PageDown') {
            event.preventDefault();
            let rowNum = (pageModel.pageSize * (pageModel.page + 1));
            if (rowNum > chats.length) {
                rowNum = chats.length;
            }
            setSelectionModel([rowNum]);
        }

        if (event.key === 'PageUp') {
            event.preventDefault();
            let rowNum = (pageModel.pageSize * (pageModel.page + 1)) - pageModel.pageSize + 1;
            setSelectionModel([rowNum]);
        }
    };

    const scrollToTopAndFocus = () => {
        setTimeout(() => {
            const gridRoot = document.querySelector('[role="grid"]');
            const firstCell = gridRoot?.querySelector('[role="cell"]');
            if (firstCell) {
                firstCell.focus();
            }
        }, 100); // A delay to ensure the grid cells are rendered. TODO: find a better way to do this.
    };

    useEffect(() => {
        const condition = (selectionModel.length > 0 && selectionModel[0] > 0);
        log.trace('useEffect(pageModel) [selectionModel]', selectionModel, '[pageModel]', pageModel, '[chats]', chats, 'condition', condition);
        if (condition) {
            scrollToTopAndFocus();
            setSelectionModel([1 + (pageModel.page * pageModel.pageSize)]);
        }
    }, [pageModel]);

    useEffect(() => {
        const condition = (chats.length !== 0 && selectionModel.length === 0);
        log.trace('useEffect(chats) [selectionModel]', selectionModel, '[pageModel]', pageModel, '[chats]', chats, 'condition', condition);
        if (condition) {
            scrollToTopAndFocus();
            setSelectionModel([1 + (pageModel.page * pageModel.pageSize)]);
        }
    }, [chats]);

    return (
        <Sidebar title='Mensagens' >
            <Paper variant="outlined" sx={{
                flexDirection: 'column',
                p: { xs: 2, md: 3 }, mb: 2,
                textAlign: 'center'
            }}>
                <React.Fragment>
                    <CssBaseline>
                        <DataGrid
                            sx={{ // disable focus outline on cells, comment this to enable focus outline and debug cell key events
                                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                                    outline: "none",
                                },
                                // to disable the header focus outline
                                // "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                                //     outline: "none",
                                // },
                            }}
                            autoFocus={true}
                            columns={columns}
                            rows={chats}
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            checkboxSelection={false}
                            localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, footerRowSelected: () => '' }}
                            slotProps={{
                                pagination: {
                                    labelRowsPerPage: 'Mensagens por página',
                                }
                            }}
                            initialState={{
                                ...chats.initialState,
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[10, 25, 50]}

                            disableMultipleRowSelection={true}
                            onRowClick={(e) => setChat(e.row)}
                            onCellKeyDown={handleCellKeyDown}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                            paginationModel={pageModel}
                            onPaginationModelChange={(newPage) => setPageModel(newPage)}
                        />

                        {showMessage && messages !== null &&
                            <Modal
                                open={showMessage}
                                onClose={handleBackdropClick}
                                slotProps={{ backdrop: { onClick: handleBackdropClick } }}
                            >
                                <Paper sx={messagerStyle} >
                                    <MessengerHeader chat={chat} />
                                    <Messenger
                                        messages={messages}
                                        handleResendMessage={handleResendMessage}
                                        newMessagesQueue={newMessagesQueue} />
                                    <MessageInput handleNewMessage={handleNewMessage}
                                        newMessage={newMessage} setNewMessage={setNewMessage} />
                                </Paper>
                            </Modal >}
                    </CssBaseline>
                </React.Fragment>
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <ModalDialog open={openDialog} setOpen={setOpenDialog}
                title={dialogTitle} message={dialogMessage}
                onConfirmation={handleConfirmation} confirmationButton={'Apagar mensagens'} />
        </Sidebar>)
}
