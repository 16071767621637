import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Sidebar from '../components/Sidebar'
import MenuItem from '@mui/material/MenuItem';
import ModalAlert from '../components/ModalAlert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ProductEditor from '../components/ProductEditor'
import CenteredLoading from '../components/CenteredLoading';
import { useAuth } from '../contexts/AuthContext';
import SearchBar from '../components/SearchBar.js';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import usePaginatedData from '../utils/usePaginatedData.js';


function SearchPage() {
    const [timer, setTimer] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const defSidebarTitle = 'Buscar Produtos';
    const [sidebarTitle, setSidebarTitle] = useState(defSidebarTitle);
    const [search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('');
    const { displaySetting } = useAuth();
    const hasMounted = useRef(false);
    const { data, paginationModel, updatePaginationModel, rowCount, loading } = usePaginatedData("/products_search", true, setAlertMessage);

    const refreshProducts = () => {
        window.document.title = defSidebarTitle + ' | ' + displaySetting('site_name');
        updatePaginationModel({ page: 0, pageSize: 10 });
    };

    useEffect(() => {
        if (hasMounted.current) {
            updatePaginationModel({ search: searchTerm, filter });
        } else {
            hasMounted.current = true;
        }
    }, [searchTerm, filter]);

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                setSearchTerm(search);
            }, 200)
        );
    }, [search]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }

    const resetSearch = () => {
        setSearchTerm('');
        setSearch('');
        refreshProducts();
    }

    return (
        <Sidebar title={sidebarTitle}>
            <Paper variant="outlined" sx={{
                display: 'flex',
                flexDirection: 'column',
                p: { xs: 2, md: 3 }, mb: 2
            }}>
                {sidebarTitle === defSidebarTitle &&
                    <Stack sx={{mb: 4}} spacing={2} direction="row">
                        <Box sx={{ flexGrow: 1 }}>
                            <SearchBar inputValue={search} setInputValue={setSearch} expandable={false} showMobile={true}></SearchBar>
                        </Box>
                        <Box sx={{ flexShrink: 0, minWidth: '150px' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >Filtro</InputLabel>
                                <Select variant='standard' value={filter} onChange={handleFilterChange} sx={{ height: 25 }}> {/*TODO: It is not aligned */}
                                    <MenuItem value=""><em>Todos</em></MenuItem>
                                    <MenuItem value={'id_dalla'}>ID Interno</MenuItem>
                                    <MenuItem value={'gtin'}>GTIN</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                }
                {(data !== null) ?
                    <ProductEditor products={data} refreshProducts={resetSearch} setSidebarTitle={setSidebarTitle} parentTitle={defSidebarTitle}
                        paginationModel={paginationModel} updatePaginationModel={updatePaginationModel} rowCount={rowCount} loading={loading}/>
                    : <CenteredLoading />
                }
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </Sidebar >
    );
}

export default SearchPage;
