import React, { useState, useEffect } from 'react';
import ApiService from '../utils/ApiService';
import CenteredLoading from './CenteredLoading';
import SettingFields from './SettingFields';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

export default function EnvironmentSettings() {
    const [settings, setSettings] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success');

    const errorNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('error');
    }

    const successNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('success');
    }

    useEffect(() => {
        ApiService.get('/environment_settings').then((response) => {
            setSettings(response);
        }).catch((error) => {
            errorNotification(error.message);
        });
    }, []);

    const saveSettings = () => {
        ApiService.post('/save_environment_settings', { "settings": settings }).then((_) => {
            successNotification('Configurações salvas com sucesso');
        }).catch((error) => {
            errorNotification(error.message);
        });
    }

    const content = () => {
        return (
            <Box>
                {SettingFields(settings, setSettings, errorNotification)}
                {settings.length > 0 && <Button onClick={saveSettings}>Salvar</Button>}
            </Box>
        )
    }

    return (
        <Box sx={{ mb: 2, maxWidth: 600, margin: 'auto' }}>
            {settings === null ? <CenteredLoading /> : content()}
            <Snackbar open={notificationMessage !== ''} autoHideDuration={6000}
                onClose={() => setNotificationMessage('')} variant={notificationType} message={notificationMessage}
            />
        </Box>
    );
}
