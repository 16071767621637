import './style/App.css';
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { useAuth } from './contexts/AuthContext';
import { SidebarProvider } from './contexts/SidebarContext';

import Login from './pages/Login'
import Logout from './pages/Logout'
import Orders from './pages/Orders'
import Order from './pages/Order'
import Search from './pages/Search'
import NotFound from './pages/NotFound'
import Products from './pages/Products'
import NewProduct from './pages/NewProduct'
import DebugProduct from './pages/DebugProduct'
import HistoryPage from './pages/History';
import Messages from './pages/Messages';
import Map from './pages/Map';
import Review from './pages/Review';
import Report from './pages/Report';
import Settings from './pages/Settings';
import Manager from './pages/Manager';
import CenteredLoading from './components/CenteredLoading';
import AppThemeProvider from './style/theme';

function App() {
    const { loading } = useAuth();

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CenteredLoading />
            </div>
        )
    }

    return (
        <AppThemeProvider>
            <BrowserRouter>
                <SidebarProvider>
                    <Routes>
                        <Route path='*' element={<NotFound />} />
                        <Route path="/" element={<Navigate to="/products" />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/order" element={<Order />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/newproduct" element={<NewProduct />} />
                        <Route path="/history" element={<HistoryPage />} />
                        <Route path="/messages" element={<Messages />} />
                        <Route path="/order/:id" element={<Order />} />
                        <Route path="/manager" element={<Manager />} />
                        <Route path="/debugproduct/:id" element={<DebugProduct />} />
                        <Route path="/map" element={<Map />} />
                        <Route path="/review" element={<Review />} />
                        <Route path="/report" element={<Report />} />
                        <Route path="/settings" element={<Settings />} />
                    </Routes>
                </SidebarProvider>
            </BrowserRouter>
        </AppThemeProvider>
    );
}

export default App;

