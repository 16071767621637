import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import ModalAlert from '../components/ModalAlert';
import Sidebar from '../components/Sidebar';
import OrdersDatagrid from '../components/OrdersDatagrid';
import CenteredLoading from '../components/CenteredLoading';
import { useAuth } from '../contexts/AuthContext';
import OrderDisplay from '../components/OrderDisplay';
import log from 'loglevel';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import usePaginatedData from '../utils/usePaginatedData.js';

export default function Orders() {
    const defSidebarTitle = 'Vendas';
    const [alertMessage, setAlertMessage] = React.useState('');
    const [showOrderDisplay, setShowOrderDisplay] = useState(false);
    const [orderOptions, setOrderOptions] = useState({});
    const [orderId, setOrderId] = useState(null);
    const [sidebarTitle, setSidebarTitle] = useState(defSidebarTitle);
    const location = useLocation();
    const { displaySetting } = useAuth();
    const { data, orderActions, paginationModel, updatePaginationModel, rowCount, loading } = usePaginatedData("/orders", true, setAlertMessage);

    useEffect(() => {
        window.document.title = defSidebarTitle + " | " + displaySetting('site_name');
    }, []);

    useEffect(() => {
        setOrderOptions(orderActions);
    }, [orderActions]);

    const handleCellClick = (e) => {
        log.debug('handleCellClick', e, 'id', e.row.id, 'product', e.row);
        if (e.field !== '__check__') {
            setOrderId(e.row.id);
            setShowOrderDisplay(true);
        }
    };

    function closeDetails() {
        setShowOrderDisplay(false);
        setSidebarTitle(defSidebarTitle);
        window.document.title = defSidebarTitle + " | " + displaySetting('site_name');
    }

    useEffect(() => {
        if (location.search === '') {
            closeDetails();
        }
    }, [location]);

    useEffect(() => {
        const handlePopstate = () => {
            if (showOrderDisplay === true && orderId !== null) {
                closeDetails();
            } else if (showOrderDisplay === false && orderId === null) {
                window.history.back();
            } else if (showOrderDisplay === false && orderId !== null) {
                setShowOrderDisplay(true);
            }
        }

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [showOrderDisplay]);

    useEffect(() => {
        const url = new URL(window.location);
        const getId = url.searchParams.get('id');

        if (getId === null && orderId !== null && showOrderDisplay === true) {
            url.searchParams.set('id', orderId);
            window.history.pushState(null, '', url.toString());
        } else if (getId !== null && orderId === null) {
            setOrderId(getId);
            setShowOrderDisplay(true);
        }
    }, [showOrderDisplay]);

    return (
        <Sidebar title={sidebarTitle}>
            <Paper
                variant="outlined"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: { xs: 2, md: 3 }, mb: 2,
                }}>
                {(orderId !== null && showOrderDisplay === true) && <OrderDisplay orderId={orderId} setSidebarTitle={setSidebarTitle} />}
                <Box sx={{ display: (showOrderDisplay !== true) ? 'block' : 'none' }}>
                    {data !== null ? <OrdersDatagrid orders={data} orderOptions={orderOptions} handleCellClick={handleCellClick}
                        paginationModel={paginationModel} updatePaginationModel={updatePaginationModel} rowCount={rowCount} loading={loading} /> :
                        <CenteredLoading />}
                </Box>
            </Paper>

            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />

        </Sidebar >
    );
}
