import log from 'loglevel';
log.setLevel(log.levels.ERROR);
let host = '';

if (process.env.REACT_APP_REACT_HOST) {
    host = process.env.REACT_APP_REACT_HOST
} else if (process.env.REACT_APP_ENV === 'production') {
    host = 'https://app.c2soft.com.br'
} else {
    log.setLevel(log.levels.DEBUG);
    host = 'http://localhost'
}

export const HOST = host
export const HTTP_PORT = process.env.REACT_APP_HTTP_PORT || '3000'
export const HTTP_URL = process.env.REACT_APP_URL || `${HOST}:${HTTP_PORT}`
export const API_PORT = process.env.REACT_APP_API_PORT || '3080'
export const API_URL = process.env.REACT_APP_API_URL || `${HOST}:${API_PORT}`

export const LOCAL_STORAGE_KEY = 'user'

export const API_ERROR_MESSAGE = 'Erro ao conectar com o servidor. Tente novamente mais tarde.'
export const API_UNAUTHORIZED_MESSAGE = 'Usuário não autorizado. Faça login novamente.'

export const IMAGES_ENDPOINT = `${API_URL}/uploads/`
export const DOCUMENTS_ENDPOINT = `${API_URL}/documents/`
export const PRODUCT_EDIT_URL = `${HTTP_URL}/products?id=`
export const ORDER_EDIT_URL = `${HTTP_URL}/orders?id=`

export const ICON_DIR = 'icons/'
export const LOGIN_TITLE = process.env.REACT_APP_LOGIN_TITLE || 'C2Soft WEB'

export const PENDING_COLOR_CLASS = 'status-pending';
export const COMPLETED_COLOR_CLASS = 'status-completed';
export const IN_PROGRESS_COLOR_CLASS = 'status-in-progress';

export const COLORS_STATUS = {
    'new': PENDING_COLOR_CLASS, 'ongoing': IN_PROGRESS_COLOR_CLASS, 'completed': COMPLETED_COLOR_CLASS,
    'default': PENDING_COLOR_CLASS
}

export const USER_PRIVILEGE = {
    ANY: 0,
    GUEST: 10,
    EDITOR: 30,
    MANAGER: 40,
    ADMIN: 80,
    SUPER_ADMIN: 100,
}