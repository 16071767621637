import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import './style/index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);
