import { createContext, useContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { useMemo } from 'react';

const ThemeUpdateContext = createContext();

function AppThemeProvider({ children} ) {
    const { displaySetting } = useAuth();

    const getThemeColors = () => {
        return {
            theme_main_color: displaySetting('theme_main_color'),
            theme_contrast_color: displaySetting('theme_contrast_color'),
            theme_secondary_color: displaySetting('theme_secondary_color'),
            theme_disabled_color: displaySetting('theme_disabled_color'),
        };
    }

    const [themeColors, setThemeColors] = useState(getThemeColors());

    const theme = useMemo(() => {
        return createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: themeColors.theme_main_color,
                    contrastText: themeColors.theme_contrast_color,
                },
                secondary: {
                    main: themeColors.theme_secondary_color,
                },
                background: {
                    paper2: '#F2F6FF',
                },
                disabled: {
                    main: themeColors.theme_disabled_color,
                },
            },
            typography: {
                h4: {
                    fontSize: '3rem',
                },
            },
        });
    }, [themeColors]);

    const updateThemeColors = (newColors) => {
        setThemeColors((prevColors) => ({
            ...prevColors, ...newColors,
        }));
    };

    return (
        <ThemeUpdateContext.Provider value={updateThemeColors}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeUpdateContext.Provider>
    );
}

export const useUpdateThemeColors = () => {
    return useContext(ThemeUpdateContext);
};

export default AppThemeProvider;
