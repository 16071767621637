import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Sidebar from '../components/Sidebar'
import ProductSubtitle from '../components/ProductSubtitle';
import ProductSubtitle2 from '../components/ProductSubtitle2';
import ModelAlert from '../components/ModalAlert';
import ProductStepper from '../components/ProductStepper';
import CenteredLoading from '../components/CenteredLoading';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

function NewProduct() {
    const [product, setProduct] = useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [steps, setSteps] = useState(['erp', 'summary']);
    const [stepTitles, setStepTitles] = useState(['Descrição', 'Confirmação']);
    const [productSaved, setProductSaved] = useState(false);
    const { displaySetting } = useAuth();

    const cleanFieldsOptions = (newProduct) => {
        for (let field in newProduct.fields) {
            if (newProduct.fields[field].value_type === 'select_search') {
                delete newProduct.fields[field].options;
            }
        }
    }

    const productSavedSuccess = () => {
        setProductSaved(true);
        setAlertSeverity('success');
        setAlertMessage("Produto criado com sucesso!");
    }

    const saveVehicleCompatibility = async (vehicleCompatibility, productId) => {
        if (vehicleCompatibility) {
            const status = !(vehicleCompatibility.vehicle_option === 'not_applicable');
            ApiService.post('/update_vehicle_compatibility_status', { product_id: productId, status: status }).then((_) => {
                productSavedSuccess();
            }
            ).catch((error) => {
                setAlertSeverity('error');
                setAlertMessage(error.message);
            });
        } else {
            productSavedSuccess();
        }
    }

    const saveChanges = async (newProduct) => {
        cleanFieldsOptions(newProduct);

        ApiService.post('/create_product', newProduct).then((response) => {
            if (response?.productId) {
                saveVehicleCompatibility(newProduct.fields.vehicle_compatibility, response.productId);
            }
        }).catch((error) => {
            setAlertSeverity('error');
            setAlertMessage(error.message);
        });
    };

    const closeButtonSuccess = () => {
        if (productSaved) {
            setAlertMessage('');
            setProduct(null);
            createNewProductFields();
        }
    }

    const createNewProductFields = async () => {
        window.document.title = `Criar Produto | ${displaySetting('site_name')}`;

        ApiService.get('/product_fields', { product_data: true }).then((response) => {
            setProduct(response);
            setAlertMessage("");
            setSteps(Object.keys(response.step_titles));
            setStepTitles(Object.values(response.step_titles));
            setProductSaved(false);
        }).catch((error) => {
            setProduct(null);
            setAlertSeverity('error');
            setAlertMessage(error.message);
        });
    }

    useEffect(() => {
        createNewProductFields();
    }, []);

    return (
        <Sidebar title='Criar Produto'>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }}>
                {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} />}
                {product !== null && <ProductSubtitle2 price={product.price} stock={product.stock} stock_location={product.stock_location} />}

                {product !== null ?
                    <ProductStepper product={product} setProduct={setProduct} steps={steps} stepTitles={stepTitles} saveChanges={saveChanges}
                        disableBackButtonWhenZeroStep={true} /> :
                    <CenteredLoading />}
            </Paper>
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} alertSeverity={alertSeverity} closeButton={closeButtonSuccess} />
        </Sidebar>
    );
}

export default NewProduct;
