import React, { useState } from 'react';
import log from 'loglevel';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import CssBaseline from '@mui/material/CssBaseline';
import MobileStepper from '@mui/material/MobileStepper';
import ModalAlert from './ModalAlert';
import DynamicComponent from './DynamicComponent';
import CenteredLoading from './CenteredLoading';
import VehicleCompatibility from './VehicleCompatibility';
import { removeUnchangedFields } from '../utils/utils';

export default function ProductStepper({ product, stepTitles, steps, saveChanges, returnFunction, disableBackButtonWhenZeroStep = false }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [activeStep, setActiveStep] = React.useState(0);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertStrings, setAlertStrings] = React.useState([]);
    const [fields, setFields] = useState(product?.fields);
    const [activePage, setActivePage] = useState(steps[activeStep]);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {
        setActivePage(steps[activeStep])
    }, [activeStep]);

    if (Object.keys(product).length === 0) {
        return (<CenteredLoading />);
    }

    const handleNextProducts = () => {
        const currentStep = steps[activeStep];
        if (currentStep === undefined) {
            return false;
        }

        const namesNeeded = [];
        for (const field in fields) {
            if (currentStep.includes(fields[field].step) && (fields[field].is_required && fields[field].value === '')) {
                namesNeeded.push(fields[field].name);
            }
        }

        if (namesNeeded.length > 0) {
            setAlertMessage('Os campos abaixo são obrigatórios');
            setAlertStrings(namesNeeded);
            return true;
        } else {
            setAlertMessage('');
            setAlertStrings([]);
            return false;
        }
    }

    const handleNext = () => {
        if (handleNextProducts()) {
            return;
        }

        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        } else {
            log.debug('ProductStepper::handleNext ', product);
            saveProducts();
        }
    };

    const handleBackStep = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else if (activeStep === 0) {
            if (returnFunction)
                returnFunction(false);
        }
    };

    const saveProducts = async () => {
        log.debug('ProductStepper::saveProducts: [product]', product);

        if (product.fields === fields) {
            if (returnFunction)
                returnFunction(false);
            return;
        }
        // TODO: fix same fields sometimes are not equal when they should be
        let newProduct = { ...product };
        newProduct.fields = removeUnchangedFields(product.fields, fields);

        saveChanges(newProduct);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justifyContent="center">
                {isMobile ? (
                    <Grid item xs={3} justifyContent="center">
                        <MobileStepper
                            variant="dots"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            sx={{ pb: 2, pt: 2 }}
                        >
                            {stepTitles.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </MobileStepper>
                    </Grid>
                ) : (
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, textAlign: 'center' }}>
                        {
                            stepTitles.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                )
                }
            </Grid>
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {Object.keys(fields).length !== 0 ?
                            (activePage !== 'vehicles' ?
                                <DynamicComponent fields={fields} setFields={setFields} step={activePage} />
                                : fields.vehicle_compatibility !== null &&
                                <VehicleCompatibility vehicleCompatibilityField={fields.vehicle_compatibility} setFields={setFields} />
                            )
                            // TODO remove loading and use when call api
                            : <CenteredLoading />}
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleBackStep} sx={{ mt: 3, ml: 1 }} disabled={disableBackButtonWhenZeroStep && activeStep === 0}>
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}>
                        {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                    </Button>
                </Box>
            </React.Fragment>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} alertStrings={alertStrings} />
        </React.Fragment >
    );
}
