import React, { useState, useEffect } from 'react';
import ApiService from '../utils/ApiService';
import CenteredLoading from './CenteredLoading';
import SettingFields from './SettingFields';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Constants from '../utils/constants';
import * as Utils from '../utils/utils';
import { useAuth } from '../contexts/AuthContext';
import { useUpdateThemeColors } from '../style/theme';

export default function DisplaySettings() {
    const [settings, setSettings] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('success');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const updateThemeColors = useUpdateThemeColors();

    const { updateDisplaySettings } = useAuth();

    const errorNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('error');
    }

    const successNotification = (message) => {
        setNotificationMessage(message);
        setNotificationType('success');
    }

    useEffect(() => {
        ApiService.get('/display_settings').then((response) => {
            setSettings(response);
            const index = response.findIndex(item => item.name === 'company_logo_path');
            if (index !== -1){
                setImagePreview(response[index].value !== null ? Constants.IMAGES_ENDPOINT + response[index].value : null);
            }
        }).catch((error) => {
            errorNotification(error.message);
        });
    }, []);

    const handleColorChange = (color, setting) => {
        updateThemeColors({
            [setting]: color,
        })
    }

    const saveSettings = async () => {
        if (selectedImage !==  null) {
            const images = await Utils.toFormData([selectedImage]);
            let response = await ApiService.postWithFormData('/upload_images', images);
            if (response) {
                const index = settings.findIndex(item => item.name === 'company_logo_path');
                if (index !== -1) {
                    let newSettings = [...settings];
                    newSettings[index].value = response.images[0];
                    setSettings(newSettings);
                }
            }
        }

        ApiService.post('/save_display_settings', { "settings": settings }).then((_) => {
            successNotification('Configurações salvas com sucesso');
            updateDisplaySettings(settings);
        }).catch((error) => {
            errorNotification(error.message);
        });
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    const content = () => {
        return (
            <Box>
                {settings.findIndex(item => item.name === 'company_logo_path') !== -1 &&
                    <FormControl sx={{ width: '100%', border: '1px solid #d3d3d3', borderRadius: '5px', padding: '15px'}}>
                        <FormControlLabel
                            control={
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                        id="imageUploadInput"
                                    />

                                    <label htmlFor="imageUploadInput">
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                border: '2px dashed #007BFF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                backgroundColor: '#f9f9f9',
                                                marginRight: '10px',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {imagePreview ? (
                                                <img
                                                    src={imagePreview}
                                                    alt="Preview"
                                                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <span style={{ color: '#888' }}/>
                                            )}
                                        </div>
                                    </label>
                                </div>
                            }
                            label={'company_logo_path'}
                        />
                        <FormHelperText style={{marginTop: '10px'}}>{'Company logo display besides the username.'}</FormHelperText>
                    </FormControl>}
                {SettingFields(settings, setSettings, errorNotification, handleColorChange)}
                {settings.length > 0 && <Button onClick={saveSettings}>Salvar</Button>}
            </Box>
        )
    }

    return (
        <Box sx={{ mb: 2, maxWidth: 600, margin: 'auto' }}>
            {settings === null ? <CenteredLoading /> : content()}
            <Snackbar open={notificationMessage !== ''} autoHideDuration={6000}
                onClose={() => setNotificationMessage('')} variant={notificationType} message={notificationMessage}
            />
        </Box>
    );
}