import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';
import * as Constants from '../utils/constants';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Snackbar } from '@mui/material';

const BackgroundStyle = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper2,
}));

const ImageDialog = ({ open, handleClose, image, title }) => {
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ display: 'flex' }}>
                <img
                    style={{ maxWidth: '100%', objectFit: 'contain', width: '800px' }}
                    src={Constants.IMAGES_ENDPOINT + image} alt="Imagem do Produto" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
}

const handleClickOpenProduct = (product_id) => {
    const win = window.open(Constants.PRODUCT_EDIT_URL + product_id, '_blank');
    win?.focus();
}

export default function ItemsList({ items, showImage = true }) {
    const [openImage, setOpenImage] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [imageTitle, setImageTitle] = React.useState('');
    const [open, setOpen] = useState(false);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    return (
        <Box sx={{ flexGrow: 1, maxWidth: 752, ml: 2, mr: 2 }}>
            <Grid item xs={12} md={12}>
                <BackgroundStyle>
                    <List dense={true}>
                        {Array.isArray(items) && items.map((item, index) => (
                            <ListItem key={index}>
                                {showImage &&
                                    <ListItemAvatar>
                                        <Avatar alt={item.product_title} src={Constants.IMAGES_ENDPOINT + item.filepath}
                                            onClick={() => {
                                                setOpenImage(true);
                                                setImage(item.filepath);
                                                setImageTitle(item.product_title);
                                            }}
                                        />
                                    </ListItemAvatar>
                                }
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <ListItemText onClick={() => handleClickOpenProduct(item.product_id)}
                                            primary={item.listing_quantity + ' x ' + item.listing_title}
                                            secondary={item.product_quantity + ' x ' + item.product_title + ' - ' + item.external_id}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography aria-label="Valor no Anúncio" sx={{ ml: 1 }}>
                                            {"R$ " + item.price}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton edge="end" aria-label="ID do Produto" onClick={() => handleCopy(item.external_id)} target="_blank">
                                            <ContentCopyIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="Anúncio" href={item.listing_url} target="_blank">
                                            <StorefrontIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </BackgroundStyle>
            </Grid>
            <ImageDialog open={openImage} handleClose={() => setOpenImage(false)} image={image} title={imageTitle} />
            <Snackbar
                open={open}
                message="Código copiado!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </Box>
    );
}
