import React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import * as Constants from '../utils/constants';
import userStore from '../stores/userStore';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const { setUser } = userStore();
    const [user, setUserState] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let storedUser = localStorage.getItem(Constants.LOCAL_STORAGE_KEY);
        if (storedUser)  {
            storedUser = JSON.parse(storedUser);

            if (!storedUser.clients || storedUser.clients.length === 0) {
                logOutUser();
            }

            setUser(storedUser);
            setUserState(storedUser);
        } else {
            logOutUser();
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        if (user && user.selectedClient.display_settings) {
            document.documentElement.style.setProperty('--status-completed-color', displaySetting('order_completed_color'));
            document.documentElement.style.setProperty('--status-in-progress-color', displaySetting('order_in_progress_color'));
            document.documentElement.style.setProperty('--status-pending-color', displaySetting('order_pending_color'));
        }
    }, [user]);

    const updateDisplaySettings = (new_settings) => {
        let new_user = { ...user };
        new_settings.forEach(setting => {
            new_user.selectedClient.display_settings[setting.name] = setting.value;
            new_user.clients.forEach(client => {
                if (client.client === new_user.selectedClient.client) {
                    client.display_settings = new_user.selectedClient.display_settings;
                }
            });
        });

        setUser(new_user);
        setUserState(new_user);
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY, JSON.stringify(new_user));
    }

    const checkPrivilege = (level) => {
        if (!user || user.selectedClient.privilege_level === undefined) {
            // Invalid local storage, log out
            logOutUser();
        }
        return user.selectedClient.privilege_level >= level;
    }

    const defaultDisplayValue = (key) => {
        switch (key) {
            case 'display_product_cost':
            case 'display_gtin_column':
            case 'display_id_column':
            case 'messages_page_enabled':
            case 'review_page_enabled':
            case 'map_page_enabled':
            case 'create_product_page_enabled':
                return false;
            case 'company_logo_path':
                return null;
            case 'theme_main_color':
                return '#004bad';
            case 'theme_contrast_color':
                return '#ffffff';
            case 'theme_secondary_color':
                return '#2c94a2';
            case 'theme_disabled_color':
                return '#b6b6b6';
            case 'order_pending_color':
                return '#f9d3d3';
            case 'order_completed_color':
                return '#c7ffcf';
            case 'order_in_progress_color':
                return '#add8e6';
            case 'min_image_width':
                return 500;
            case 'min_image_height':
                return 500;
            case 'site_name':
                return 'C2 Software Solutions';
            default:
                return null;
        }
    }

    const displaySetting = (key) => {
        if (user && user.selectedClient.display_settings && user.selectedClient.display_settings[key]) {
            return user.selectedClient.display_settings[key];
        } else {
            return defaultDisplayValue(key);
        }
    }

    const changeClient = (client_index) => {
        let new_user = { ...user };
        new_user.selectedClient = new_user.clients[client_index];
        setUser(new_user);
        setUserState(new_user);
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY, JSON.stringify(new_user));
    }

    const logInUser = (userData) => {
        setUser(userData);
        setUserState(userData);
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY, JSON.stringify(userData));
    };

    const logOutUser = () => {
        setUser(null);
        setUserState(null);
        localStorage.removeItem(Constants.LOCAL_STORAGE_KEY);
    };

    return (
        <AuthContext.Provider value={{ logInUser, logOutUser, user, checkPrivilege, displaySetting, changeClient, loading, updateDisplaySettings }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    return useContext(AuthContext);
};

export { AuthContext, AuthProvider, useAuth };
