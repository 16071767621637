import React from 'react';
import Typography from '@mui/material/Typography';
import * as Utils from '../utils/utils';
import log from 'loglevel';

export default function ProductSubtitle({ external_id, title, gtin, isActive = true }) {
    log.trace('ProductSubtitle: [title, gtin]', title, gtin);
    return (
        <Typography component="h1" variant="h6" align="center" sx={{ color: isActive ? 'black' : 'red' }}>
            {Utils.productSubtitle(external_id, title, gtin)}
        </Typography>
    )
}
