import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProductSubtitle from './ProductSubtitle';
import ProductSubtitle2 from './ProductSubtitle2';

export default function DisplayAll({ product }) {
    return (
        <React.Fragment>
            <Container component="main" sx={{ mb: 2, mt: 2, height: 'calc(var(--vh, 1vh) * 95)' }}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                    {product !== null && <ProductSubtitle external_id={product.external_id} title={product.title} gtin={product.gtin} />}
                    {product !== null && <ProductSubtitle2 price={product.price} stock={product.stock} stock_location={product.stock_location}
                        cost={product.cost} />}

                    <Grid container spacing={3} sx={{ width: '60%', margin: 'auto' }}>
                        <Grid item xs={12}>
                            {Object.entries(product.fields).map(([key, value]) => {
                                return (
                                    /* Label: key; Value: value */
                                    <Grid container spacing={3} key={key}>
                                        <Grid item xs={4}>
                                            <Typography component="h1" variant="body2" align="left">
                                                {key}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography component="h1" variant="body2" align="right">
                                                {value.value === "" || value.value === null ? "N/A" : value.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
