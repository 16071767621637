import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '../utils/ApiService';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';
import * as Constants from '../utils/constants';
import ModalAlert from '../components/ModalAlert';
import AppThemeProvider from '../style/theme';
import { useUpdateThemeColors } from '../style/theme';

function Copyright(props) {
    return (<Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://c2soft.com.br/">
            C2 Software Solutions
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    );
}

export default function LoginPage() {
    const navigate = useNavigate();
    const { logInUser, logOutUser, user, loading, displaySetting } = useAuth();
    const [alertMessage, setAlertMessage] = React.useState('');
    const updateThemeColors = useUpdateThemeColors();

    React.useEffect(() => {
        if (!loading) {
            if (user) {
                navigate('/')
            }
        }
    }, [user, loading, navigate])

    const handleSubmit = (event) => {
        event.preventDefault();
        onButtonClick()
    };

    function setErroLogin() {
        setEmailError('Usuário ou senha inválida')
        setPasswordError("Usuário ou senha inválida")
        logOutUser();
    }

    const [username, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [usernameError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const onButtonClick = async () => {
        setEmailError("")
        setPasswordError("")

        if (username === "")
            return setEmailError("Entre com o nome de usuário")

        if (password === "")
            return setPasswordError("Favor inserir uma senha")

        await logIn();
    }

    const updateThemeColorsFromUser = (userData) => {
        const newDisplaySettings = userData.selectedClient.display_settings;
        updateThemeColors({
            theme_main_color: newDisplaySettings.theme_main_color? newDisplaySettings.theme_main_color: displaySetting('theme_main_color'),
            theme_contrast_color: newDisplaySettings.theme_contrast_color? newDisplaySettings.theme_contrast_color: displaySetting('theme_contrast_color'),
            theme_secondary_color: newDisplaySettings.theme_secondary_color? newDisplaySettings.theme_secondary_color: displaySetting('theme_secondary_color'),
            theme_disabled_color: newDisplaySettings.theme_disabled_color? newDisplaySettings.theme_disabled_color: displaySetting('theme_disabled_color'),
        });
    }

    const logIn = async () => {
        try {
            const response = await ApiService.post('/auth', { "username": username, "password": password }, null, false);
            if (response) {
                const userData = {
                    username,
                    token: response.token,
                    clients: response.clients,
                    selectedClient: response.clients[0]
                };

                updateThemeColorsFromUser(userData);

                logInUser(userData);
            } else {
                setErroLogin();
            }
        } catch (error) {
            setErroLogin();
        }
    };

    return (
        <AppThemeProvider>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar src="logo.png" style={{ width: '60px', height: '60px' }} sx={{ mb: 2 }} />
                        <Typography component="h1" variant="h4">
                            {Constants.LOGIN_TITLE}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Nome Usuário"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={ev => setEmail(ev.target.value)}
                                value={username}
                                error={usernameError !== ""}
                                helperText={usernameError}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={ev => setPassword(ev.target.value)}
                                value={password}
                                error={passwordError !== ""}
                                helperText={passwordError}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Entrar
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
        </AppThemeProvider>
    );
}
